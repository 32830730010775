button.ada-embed-button {
  background-color: #0074ff !important;
}

.summaryButtons {
  padding: 20px;
}

.uploadDocumentBtn {
  margin: 10px 0 30px 0 !important;
}

.btn {
  border-width: $border-thick;
  background-color: $transparent-bg;
  font-weight: $font-weight-normal;
  font-family: "Open Sans", sans-serif;

  @include opacity(0.8);
  padding: $padding-base-vertical $padding-base-horizontal;

  @include btn-styles($default-color, $default-states-color);

  &:hover,
  &:focus {
    @include opacity(1);
    outline: 0 !important;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    @include box-shadow(none);
    outline: 0 !important;
  }

  &.btn-icon {
    padding: $padding-base-vertical;
  }
}

.btn-link {
  border: none !important;
  font-family: "Open Sans", sans-serif;
}

// Apply the mixin to the buttons
//.btn-default { @include btn-styles($default-color, $default-states-color); }
.btn-primary {
  @include btn-styles($primary-color, $primary-states-color);
}

.btn-success {
  @include btn-styles($success-color, $success-states-color);
}

.btn-info {
  @include btn-styles($info-color, $info-states-color);
}

.btn-warning {
  @include btn-styles($warning-color, $warning-states-color);
}

.btn-danger {
  @include btn-styles($danger-color, $danger-states-color);
}

.myAccountStartApplication {
  margin-left: auto !important;
  margin-right: 20px;
}

.btn-neutral {
  @include btn-styles($white-color, $white-color);

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-color: $white-color;
    color: $default-color;
  }

  &.btn-fill,
  &.btn-fill:hover,
  &.btn-fill:focus {
    color: $default-color;
  }

  &.btn-simple:active,
  &.btn-simple.active {
    background-color: transparent;
  }
}

.btn {
  &:disabled,
  &[disabled],
  &.disabled {
    @include opacity(0.5);
    border: 1px solid $uwRed;
    color: $uwRed;
    background-color: transparent;
  }
}

.btn-round {
  border-width: $border-thin;
  border-radius: $btn-round-radius !important;
  padding: $padding-round-vertical $padding-round-horizontal;

  &.btn-icon {
    padding: $padding-round-vertical;
  }
}

.btn-simple {
  border: $none;
  font-size: $font-size-medium;
  padding: $padding-base-vertical $padding-base-horizontal;

  &.btn-icon {
    padding: $padding-base-vertical;
  }
}

.btn-lg {
  @include btn-size(
    $padding-large-vertical,
    $padding-large-horizontal,
    $font-size-large,
    $border-radius-large
  );
  font-weight: $font-weight-normal;
}

.btn-sm {
  @include btn-size(
    $padding-small-vertical,
    $padding-small-horizontal,
    $font-size-small,
    $border-radius-small
  );
}

.btn-xs {
  @include btn-size(
    $padding-xs-vertical,
    $padding-xs-horizontal,
    $font-size-small,
    $border-radius-small
  );
}

.btn-wd {
  min-width: 140px;
}

.btn-group.select {
  width: 100%;
}

.btn-group.select .btn {
  text-align: left;
}

.btn-group.select .caret {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 8px;
}

.back-btn {
  background-color: $uwBlue;
  border-color: $uwBlue;
  text-transform: uppercase;
  min-width: 100px;
  height: 48px;

  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;

  align-items: center;
  text-align: center;

  color: #ffffff;
  border-radius: 8px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
}

.clearObject {
  margin: 20px 0;
  color: $uwRed;
  border-color: $uwRed;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
}

.back-btn:focus,
.back-btn:hover,
.back-btn:active {
  background-color: $uwBlue;
  border-color: $uwBlue;
  text-transform: uppercase;

  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;

  align-items: center;
  text-align: center;

  color: #ffffff;
  border-radius: 8px;

  -webkit-box-shadow: inset 0px 5px 10px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px 5px 10px 5px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px 5px 10px 5px rgba(0, 0, 0, 0.25);
}

.essay-btn {
  height: 40px !important;
  width: 150px !important;
  min-width: 100px !important;
  padding: 0 !important;
  margin-bottom: 10px;
  float: right;
}

.save-btn,
.save-btn:active {
  min-width: 200px;
  height: 48px;
  background-color: $uwRed;
  border-color: $uwRed;
  text-transform: uppercase;
  opacity: 1;

  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;

  align-items: center;
  text-align: center;

  color: #ffffff;
  border-radius: 8px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
}

.save-btn:hover,
.save-btn:focus,
.btn-pay:hover,
.btn-pay:focus {
  background-color: $uwRed;
  border-color: $uwRed;
  text-transform: uppercase;

  font-family: "Open Sans", sans-serif;
  font-weight: 600;

  align-items: center;
  text-align: center;

  color: #ffffff;
  border-radius: 8px;

  -webkit-box-shadow: inset 0px 5px 10px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px 5px 10px 5px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px 5px 10px 5px rgba(0, 0, 0, 0.25);
}

.save-btn:hover,
.save-btn:focus {
  font-size: 16px;
}

.btn-pay, .btn-pay:active {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 3px 12px;

  background-color: $uwRed;
  border-color: $uwRed;
  text-transform: uppercase;
  opacity: 1;

  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;

  align-items: center;
  text-align: center;

  color: #ffffff;
  border-radius: 8px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
}

.btn-pay:hover,
.btn-pay:focus {
  font-size: 14px;
}

.tertiaryButton,
.tertiaryButton:active {
  min-width: 200px;
  height: 48px;
  background-color: $uwDarkGray;
  border-color: $uwDarkGray;
  text-transform: uppercase;
  opacity: 1;

  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;

  align-items: center;
  text-align: center;

  color: #ffffff;
  border-radius: 8px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
}

.tertiaryButton:hover,
.tertiaryButton:focus {
  min-width: 200px;
  height: 48px;
  background-color: $uwDarkGray;
  border-color: $uwDarkGray;
  text-transform: uppercase;

  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;

  align-items: center;
  text-align: center;

  color: #ffffff;
  border-radius: 8px;

  -webkit-box-shadow: inset 0px 5px 10px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px 5px 10px 5px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px 5px 10px 5px rgba(0, 0, 0, 0.25);
}

.careerClusterButtons {
  padding: 5px;
  margin: 5px;
  text-align: center;
}

.careerClusterButtons > Button {
  padding: 5px;
  margin: 5px;
  text-align: center;
}

.btnMargin {
  margin: 0px 10px;
}

//fix bug for the select items in btn-group
.btn-group.select {
  overflow: hidden;
}

.btn-group.select.open {
  overflow: visible;
}

.deleteBtn {
  border: none !important;
  border-color: #3a3a3a;
  color: #3a3a3a;
  border-radius: 10px;
}

.btn-download {
  border: none !important;
  color: #3a3a3a;
}

.prev-download .btn-download {
  margin-top: 23px
}

.change-btn {
  border: none !important;
  color: black;
  font-weight: normal;
}

.editBtn {
  border: none !important;
  border-color: #3a3a3a;
  color: #3a3a3a;
  border-radius: 10px;
}

.buttonReviewIncomplete {
  color: #ffffff;
  border-radius: 8px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 16px;
  float: right;
  background-color: $uwRed;
  border-color: $uwRed;
  min-width: 200px;
  text-transform: uppercase;
  opacity: 1;
}

.oldApplicationButton {
  background-color: $uwBlue;
  width: 300px;
  margin-bottom: 40px;
}

.add-content {
  margin: 0 auto;
  display:block
}

input[type='button'].btn-general {
  -webkit-appearance: button;
  cursor: pointer;
  align-self: center;
  background-color: $uwBlue;
  border-radius: 10px;
  font-size: 16px;
  height: 48px;
  margin-bottom: 10px;
  margin-top: 10px;
  min-width: 200px;
  text-transform: uppercase;
  width: auto;
  font-family: Open Sans,sans-serif;
  font-weight: 600;
  border: 0;
  color: #fff;
  display: inline-block;
  line-height: inherit;
  padding: 9px 15px;
  text-decoration: none;
  text-align: center
}

input[type='button'].btn-general.triggered {
  border: 2px solid $uwBlue;
  color: $uwBlue;
  background-color: unset;
}

input[type='button'].primary {
  background-color: $uwRed;
}

div.tabs .btn-wrapper .btn-general.link-da {
  width: unset;
  margin: 24px auto
}

input[type='button'].btn-general + input[type='button'].btn-general {
  margin-left: 12px
}

@media screen and (max-width: 699px) {
  div.btn-wrapper input[type="button"].btn-general + input[type="button"].btn-general {
    margin-left: 0
  }
}