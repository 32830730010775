.form-control::-moz-placeholder {
  @include placeholder($medium-gray, 1);
}
.form-control:-moz-placeholder {
  @include placeholder($medium-gray, 1);
}
.form-control::-webkit-input-placeholder {
  @include placeholder($medium-gray, 1);
}
.form-control:-ms-input-placeholder {
  @include placeholder($medium-gray, 1);
}

.disabledCampusCard {
  background-color: #eee !important;
  opacity: 1 !important;
  cursor: not-allowed;
}

.terms-i-btn {
    margin-top: 24px
}

select option:disabled {
  color: #000;
  font-weight: bold;
  cursor: not-allowed;
}

.campusFill {
  background-color: $uwBlue !important;
  color: white;
  font-weight: bold;
}

.form-control {
  border: 1px solid #878787;
  box-sizing: border-box;

  background: #ffffff;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  @include input-size(
    $padding-base-vertical,
    $padding-base-horizontal - 4,
    $height-base
  );
  @include box-shadow(none);

  &::placeholder {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #bdbdbd;
  }

  &:focus {
    background-color: $white-bg;
    border: 1px solid $uwBlue;
  }

  &:active {
    color: black !important;
  }

  .has-success &,
  .has-error &,
  .has-success &:focus,
  .has-error &:focus {
    border-color: $light-gray;
    @include box-shadow(none);
  }

  .has-success & {
    color: $success-color;
  }
  .has-success &:focus {
    border-color: $success-color;
  }
  .has-error & {
    color: $danger-color;
  }
  .has-error &:focus {
    border-color: $danger-color;
  }

  & + .form-control-feedback {
    border-radius: $border-radius-large;
    font-size: $font-size-base;
    margin-top: -7px;
    position: absolute;
    right: 10px;
    top: 50%;
    vertical-align: middle;
  }

  .open & {
    border-radius: $border-radius-base $border-radius-base 0 0;
    border-bottom-color: transparent;
  }
}

.input-lg {
  height: 55px;
  padding: $padding-large-vertical $padding-large-horizontal;
}

.has-error {
  .form-control-feedback {
    color: $danger-color;
  }
}
.has-success {
  .form-control-feedback {
    color: $success-color;
  }
}

.input-group-addon {
  background-color: $white-color;
  border: 1px solid $light-gray;
  border-radius: $border-radius-base;

  .has-success &,
  .has-error & {
    background-color: $white-color;
    border: 1px solid $light-gray;
  }
  .has-error .form-control:focus + & {
    border-color: $danger-color;
    color: $danger-color;
  }
  .has-success .form-control:focus + & {
    border-color: $success-color;
    color: $success-color;
  }
  .form-control:focus + &,
  .form-control:focus ~ & {
    background-color: $white-color;
    border-color: $dark-gray;
  }
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-right: 0 none;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
  border-left: 0 none;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: $uwLightGray;
  color: black;
  cursor: not-allowed;
}

.input-group-btn .btn {
  border-width: $border-thin;
  padding: $padding-round-vertical $padding-base-horizontal;
}
.input-group-btn .btn-default:not(.btn-fill) {
  border-color: $medium-gray;
}

.input-group-btn:last-child > .btn {
  margin-left: 0;
}

.input-group-focus .input-group-addon {
  border-color: $dark-gray;
}

.static-form-group {
  text-align: center;
  padding-top: 32px;
}

.essay-columns {
    padding: 12px 0 24px
}

.essay-columns > div:first-child {
    margin-right: 12px;
}

@media (min-width: 992px) {
    .essay-columns > div:first-child {
        width: calc(50% - 12px)
    }
}

p.word-count + h2 {
    margin-top: 48px
}

p.optional-essay {
  font-size: 15px;
  margin: 0 auto 10px;
  padding: 0 18px;
  text-align: center;
  max-width: 600px
}

.prompt p:last-child {
    margin-bottom: 0
}

.obligation-to-report {
    display: inline-block;
    color: #1DC7EA;
    font-weight: bold;
    margin: 18px 0 10px;
}

.obligation-to-report:hover {
    cursor: pointer
}

.essay-tips {
    border-left: 6px solid #ccc;
    //background-color: #f7f7f7;
    padding: 0 8px 0 12px;
    margin: 0 0 10px 48px
}

.essay-tips p {
    font-size: 15px;
    margin: 14px 0 6px
}

.essay-tips > p:first-child {
    font-weight: bold
}

.essay-tips li {
    font-size: 15px;
    line-height: 18px;
    padding-bottom: 6px
}

.word-count small {
    margin-left: 12px
}

.rtf-wrapper {
  min-height: 500px;
  border: 1px solid #ccc;
  padding-top: 20px !important;
}

input[type="date"] {
  font-weight: 500;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}

input[type="file"] {
  font-weight: 500;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}

.react-date-picker__inputGroup {
  font-size: 20px !important;
}

.react-date-picker__wrapper {
  border: none !important;
}

.passwordError {
  color: $uwRed !important;
  border: 1px solid $uwRed;
}

.failedLogin:not(:focus) {
  border: 1px solid red !important;
  color: red !important;
}

::placeholder {
  font-family: "Open Sans", sans-serif !important;
}

//input:required:invalid, input:focus:invalid {
//    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAeVJREFUeNqkU01oE1EQ/mazSTdRmqSxLVSJVKU9RYoHD8WfHr16kh5EFA8eSy6hXrwUPBSKZ6E9V1CU4tGf0DZWDEQrGkhprRDbCvlpavan3ezu+LLSUnADLZnHwHvzmJlvvpkhZkY7IqFNaTuAfPhhP/8Uo87SGSaDsP27hgYM/lUpy6lHdqsAtM+BPfvqKp3ufYKwcgmWCug6oKmrrG3PoaqngWjdd/922hOBs5C/jJA6x7AiUt8VYVUAVQXXShfIqCYRMZO8/N1N+B8H1sOUwivpSUSVCJ2MAjtVwBAIdv+AQkHQqbOgc+fBvorjyQENDcch16/BtkQdAlC4E6jrYHGgGU18Io3gmhzJuwub6/fQJYNi/YBpCifhbDaAPXFvCBVxXbvfbNGFeN8DkjogWAd8DljV3KRutcEAeHMN/HXZ4p9bhncJHCyhNx52R0Kv/XNuQvYBnM+CP7xddXL5KaJw0TMAF8qjnMvegeK/SLHubhpKDKIrJDlvXoMX3y9xcSMZyBQ+tpyk5hzsa2Ns7LGdfWdbL6fZvHn92d7dgROH/730YBLtiZmEdGPkFnhX4kxmjVe2xgPfCtrRd6GHRtEh9zsL8xVe+pwSzj+OtwvletZZ/wLeKD71L+ZeHHWZ/gowABkp7AwwnEjFAAAAAElFTkSuQmCC);
//    background-position: right top;
//    background-repeat: no-repeat;
//    -moz-box-shadow: none;
//}
//input:required:valid {
//    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAepJREFUeNrEk79PFEEUx9/uDDd7v/AAQQnEQokmJCRGwc7/QeM/YGVxsZJQYI/EhCChICYmUJigNBSGzobQaI5SaYRw6imne0d2D/bYmZ3dGd+YQKEHYiyc5GUyb3Y+77vfeWNpreFfhvXfAWAAJtbKi7dff1rWK9vPHx3mThP2Iaipk5EzTg8Qmru38H7izmkFHAF4WH1R52654PR0Oamzj2dKxYt/Bbg1OPZuY3d9aU82VGem/5LtnJscLxWzfzRxaWNqWJP0XUadIbSzu5DuvUJpzq7sfYBKsP1GJeLB+PWpt8cCXm4+2+zLXx4guKiLXWA2Nc5ChOuacMEPv20FkT+dIawyenVi5VcAbcigWzXLeNiDRCdwId0LFm5IUMBIBgrp8wOEsFlfeCGm23/zoBZWn9a4C314A1nCoM1OAVccuGyCkPs/P+pIdVIOkG9pIh6YlyqCrwhRKD3GygK9PUBImIQQxRi4b2O+JcCLg8+e8NZiLVEygwCrWpYF0jQJziYU/ho2TUuCPTn8hHcQNuZy1/94sAMOzQHDeqaij7Cd8Dt8CatGhX3iWxgtFW/m29pnUjR7TSQcRCIAVW1FSr6KAVYdi+5Pj8yunviYHq7f72po3Y9dbi7CxzDO1+duzCXH9cEPAQYAhJELY/AqBtwAAAAASUVORK5CYII=);
//    background-position: right top;
//    background-repeat: no-repeat;
//}
