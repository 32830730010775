.my-account-container .cardContent {
  margin: 25px !important;
  padding-bottom: 12px
}

.DOCUMENTS .cardContent {
  margin: 25px 50px !important;
}

.card {
  border-radius: $border-radius;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  background-color: #ffffff;

  .cardHeader {
    margin: 40px 60px 0px 60px;
  }

  .title {
    font-family: brandon-grotesque, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;

    color: $uwDarkRed;
  }

  .image {
    width: 100%;
    overflow: hidden;
    height: 260px;
    border-radius: $border-radius-base $border-radius-base 0 0;
    position: relative;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;

    img {
      width: 100%;
    }
  }

  .filter {
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.68);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;

    @include opacity(0);

    .btn {
      @include vertical-align();
    }
  }

  &:hover .filter {
    @include opacity(1);
  }

  .btn-hover {
    @include opacity(0);
  }

  &:hover .btn-hover {
    @include opacity(1);
  }

  .cardContent {
    margin: 25px 60px 25px 60px;
  }

  .header {
    padding: 15px 15px 0;
  }

  .category,
  label {
    margin-bottom: 0px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;

    color: $dark-gray;

    i {
      font-size: $font-paragraph;
    }
  }

  .title {
    margin: $none;
    color: $uwRed;
    font-weight: $font-weight-bold;
    font-size: 24px;
    font-family: brandon-grotesque, sans-serif;
  }

  .avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px;
  }

  .description {
    font-size: $font-size-base;
    color: #333;
  }

  .stats {
    color: #a9a9a9;
  }

  .author {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  .author i {
    font-size: $font-size-base;
  }

  h5 {
    text-transform: uppercase;
    font-family: brandon-grotesque, sans-serif;
  }

  h6 {
    font-size: $font-size-small;
    text-transform: none;
    font-family: brandon-grotesque, sans-serif;
  }

  &.card-separator:after {
    height: 100%;
    right: -15px;
    top: 0;
    width: 1px;
    background-color: $medium-gray;
    content: "";
    position: absolute;
  }

  .ct-chart {
    margin: 30px 0 30px;
    height: 245px;
  }

  .table {
    tbody td:first-child,
    thead th:first-child {
      padding-left: 15px;
    }

    tbody td:last-child,
    thead th:last-child {
      padding-right: 15px;
    }
  }

  .alert {
    border-radius: $border-radius-base;
    position: relative;

    &.alert-with-icon {
      padding-left: 65px;
    }
  }
}

.card .card-footer {
  min-height: 100px;
  width: 100%;
  background-color: $uwLightGray;
  border-radius: 0px 0px $border-radius $border-radius;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center
}

.card .card-footer.extra-margin {
  margin-top: 50px
}

.card-footer-wrapper {
  flex-direction: row;
  display: flex;
}

.card .card-footer a {
  display: inline-block;
  min-width: 200px;
  height: 48px;
  padding: 16px 0;
  background-color: #444444;
  border-color: #444444;
  text-transform: uppercase;
  opacity: 1;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.75);
  line-height: 16px;
  margin-left: 20px
}

.card .card-footer a,
.card .card-footer button {
  margin-left: 20px
}

.card .card-footer a.action {
  background-color: $uwRed;
  border-color: $uwRed;
}

.card .card-footer a.action:hover,
.card .card-footer a.action:focus {
  background-color: $uwRed;
  border-color: $uwRed;
  color: #ffffff;
  border-radius: 8px;
  -webkit-box-shadow: inset 0px 5px 10px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px 5px 10px 5px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px 5px 10px 5px rgba(0, 0, 0, 0.25);
}

.card .card-footer a:first-child,
.card .card-footer button:first-child {
  margin-left: 0
}

.card-user,
.card-price {
  .footer {
    padding: 5px 15px 10px;
  }

  hr {
    margin: 5px 15px;
  }
}

.initialInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.initialInfoCard:focus,
.initialInfoCard:hover,
.initialInfoCard:active {
  -webkit-box-shadow: inset 0px 5px 10px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0px 5px 10px 5px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px 5px 10px 5px rgba(0, 0, 0, 0.25);
}

.initialInfoCard {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 10px 20px;

  width: 210px;
  height: 250px;
  background: #ffffff;
  border-radius: 8px;

  border: 2px solid $uwBlue;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);

  & .textImages {
    width: 75px;
    height: 75px;
  }

  & .initialInfoCardImg {
    height: 60%;
    width: 100%;

    //border: 2px solid magenta;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > img {
      max-width: 120px;
      max-height: 130px;
    }
  }

  & .initialInfoCardText {
    height: 40%;
    width: 90%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > p {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 150%;

      color: $uwBlue;

      position: absolute;
    }
  }
}

.card-plain {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;

  .image {
    border-radius: 4px;
  }
}

.card-stats {
  .icon-big {
    font-size: 3em;
    min-height: 64px;

    i {
      font-weight: bold;
      line-height: 59px;
    }
  }

  .numbers {
    font-size: 2em;
    text-align: right;

    p {
      margin: 0;
    }
  }
}

.infoAlertContainer {
  background-color: white;
  border-radius: 8px;
  margin-top: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.infoAlertHeader {
  height: 100px;
  border-radius: 8px 8px 0 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $uwRed;
  width: 100%;
}

.nextStepsContainer {
  background-color: $uwRed;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.campusCard {
  border-radius: 25px;
  background-color: $uwLightGray;
  margin: 10px;
  width: 240px;
  height: 160px;
}

.da-campusCard {
  border-radius: 25px;
  background-color: $uwLightGray;
  margin: 10px;
  width: 240px;
  height: 260px;
}

.da-campusCard input {
  display: block;
  background-color: #fff;
  width: 96%;
  margin-left: 2%;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  white-space: normal;
  margin-bottom: 8px;
  border-radius: 10px
}

.da-campusCard input:nth-child(2) {
  margin-top: 4px;
}

.da-campusCard input[disabled] {
  background-color: #eee;
  border: 1px solid #878787;
  padding: 7px 11px
}

.da-campusCard input:last-child {
  margin-bottom: 0
}

.da-campusCard input.selected {
  font-weight: bold;;
  background-color: $uwBlue;
  color: #fff
}

.img-wrapper {
  height: 75px;
  display: flex;
  justify-content: center;
}

.img-wrapper img {
  object-fit: contain
}

img.img-major {
  position: absolute;
  top: 41px;
  right: 60px;
  max-width: 242px;
  max-height: 64px
}

@media screen and (max-width: 750px) {
  img.img-major {
      display: none
  }
}

.loading-page {
    text-align: center
}
