/*   Labels
*/
.form-group label {
    display: block
}

.form-group label.one-line {
    display: inline-block
}

label.invalid,
div.text.invalid {
    color: #D44427
}

label small {
    margin-left:4px
}

.card .view-mismatch label {
    color: #aaa;
}

/*  Textboxes
*/
form > input[type='text'].form-control,
form > input[type='email'].form-control,
form > input[type='password'].form-control,
form > input[type='number'].form-control,
form > input[type='datetime'].form-control,
form > input[type='ssn'].form-control {
    width: 100%;
    height:50px;
    margin-bottom: 20px;
    border-radius: 10px;
    border-color: #ccc
}

div.invalid .form-group input[type='datetime'] {
    background-color: #FAF0F0;
    border-color: #d79f9f
}

div[class*="col-"].invalid input[type='text'],
div[class*="col-"].invalid input[type='email'],
div[class*="col-"].invalid input[type='password'],
div[class*="col-"].invalid input[type='number'],
div[class*="col-"].invalid input[type='ssn'],
div[class*="col-"].invalid select,

input[type='text'].invalid,
input[type='email'].invalid,
input[type='password'].invalid,
input[type='number'].invalid,
input[type='ssn'].invalid,
select.invalid {
    background-color: #FAF0F0;
    border-color: #d79f9f
}

input[type='text'].floating,
input[type='email'].floating,
input[type='password'].floating,
input[type='number'].floating,
input[type='ssn'].floating,
select.floating {
    max-width: 400px;
}

input[type='text'].floating-short,
input[type='email'].floating-short,
input[type='password'].floating-short,
input[type='number'].floating-short,
input[type='ssn'].floating-short,
select.floating-short {
    max-width: 200px;
}

input[type='button'].btn-general.btn-da-linked {
    background-color: #D44427;
    margin: 0 auto
}

/*  Checkboxes
*/
.form-group .checkbox {
    padding: 0 10px
}

@media (min-width: 992px) {
    .form-group.checkbox-list div.checkbox {
        margin-bottom: 0
    }
}

/*  Phone controls
*/

.react-tel-input .form-control {
    border-radius: 10px;
}

.react-tel-input .selected-flag,
.react-tel-input .flag-dropdown {
    border-radius: 10px 0 0 10px
}

.react-tel-input.hide-invalid-phone .form-control.invalid-number {
    background-color: #fff;
    border-color: #ccc
}

form > .react-tel-input.hide-invalid-phone .flag-dropdown.invalid-number {
    border-color: #ccc
}

.form-group .react-tel-input .flag-dropdown:not(.invalid-number) {
    border-color: #878787
}

.form-group .react-tel-input {
    height: 40px;
}

.form-group .react-tel-input .form-control {
    height: 40px !important;
    border-color: #878787
}

.form-group .react-tel-input .form-control,
.form-group .react-tel-input.hide-invalid-phone .form-control.invalid-number,
.form-group .react-tel-input.hide-invalid-phone .flag-dropdown.invalid-number {
    border-color: #878787
}

/*  Date Controls
*/
.date-picker input, .date-picker select {
    display: inline-block
}

.date-picker .form-group select:nth-child(2) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: unset;
}

.date-picker .form-group select:nth-child(3) {
    border-radius: 0;
    border-radius: 0;
    border-left: unset;
    border-right: unset;
    width: 75px
}

.date-picker .form-group input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: unset;
    min-width: unset;
}

.date-picker .form-group select:nth-child(2) {
    width: 66%
}

.date-picker .form-group input {
    width: 34%
}

.date-picker.full-date .form-group select:nth-child(2) {
    width: calc(60% - 38px)
}

.date-picker.full-date .form-group input {
    width: calc(40% - 38px)
}

/*  Mid-form buttons
*/
div.row button.open-sub-form {
    margin: 0 0 24px 15px
}

div.row button.mid-form {
    margin-left: 15px;
    height: 40px;
    border-radius: 10px;
    font-weight: bold;
    text-transform: uppercase;
}

div.row button.mid-form:not([disabled]) {
    background-color: #005777;
    border-color: #005777;
    color: #fff;
}

div.row.addr-validation-err {
    text-align: center
}

div.row.addr-validation-err h3 {
    max-width: 500px;
    margin: 0 auto
}

div.row.addr-validation-err h3 + button {
    margin-top: 12px
}

/*  Submit buttons
*/
.submit-button[disabled] {
    opacity: 0.5;
    background-color: transparent;
    border: 2px solid #D44427;
    color: #D44427;
}

.submit-button[disabled]:hover {
    cursor: not-allowed;
    background-color: transparent;
    border: 2px solid #D44427;
    color: #D44427;
}

/*  Rows
*/
div.row {
    margin: 0
}

@media (min-width: 992px) {
    div.card form div.row [class*="col-"] {
        padding-left: 3px !important;
        padding-right: 3px !important;
    }
}

/*  One-Offs
*/
div.no-phone {
    margin: 20px 0
}

div.no-phone label {
    font-size: 16px;
    line-height: 22px;
}

div.no-phone div.checkbox {
    padding-left: 25px;
}
