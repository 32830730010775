/*           Font Smoothing      */
body,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.navbar,
.brand,
.btn-simple,
.alert,
a,
.td-name,
td,
button.close {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Open Sans", sans-serif;
  font-weight: $font-weight-normal;
}

.campusExceptionInfo {
  color: $uwBlue;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
}

.campusExceptionInfo:hover,
.campusExceptionInfo:active,
.campusExceptionInfo:focus {
  color: $uwLightBlue;
}

p {
  font-size: $font-paragraph;
  line-height: $line-height-general;
  font-family: "Open Sans", sans-serif;
}

.textMargin {
  margin: 0 60px 20px;
}

p.campus-blocks {
  text-align: left;
  margin: 36px 60px 12px;
}

.campus-blocks a {
  float: right;
  font-size: 16px;
}

.campus-blocks a:hover {
  cursor:pointer
}

p.da-campus {
  text-align: center;
  margin: 0 auto;
  max-width: 80%;
}

p.da-campus + p.da-campus {
  margin: 18px auto;
}

p.da-campus a:hover {
  cursor: pointer;
}

form + p.da-campus {
  margin-top: 18px
}

p.addtl-campus,
ul.addtl-campus {
  text-align: left;
  margin: 0 84px 12px
}

hr.pin-content-to-bottom {
  margin-top: 104px
}

b {
  font-family: "Open Sans", sans-serif;
}

strong sup a {
  font-weight: bold;
  margin-left: 4px
}

strong sup a:hover {
  cursor: pointer;
}

.uwBlueText {
  color: $uwBlue;
}

.uwRedText {
  color: $uwRed !important;
}

.uwRedTextI {
  color: $uwRed !important;
}

div.alert-msg {
    color: $uwRed;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px
}

div.alert-msg span {
    margin-right: 12px;
    display: flex;
    align-items: center;
}

div.alert-msg span img {
    height: 30px;
    width: 30px;
    align-self: center
}

div.alert-msg p {
    width: calc(100% - 42px);
    font-weight: bold;
    margin: 0
}

.whiteText {
  color: white;
}

.fixATag {
  color: $uwBlue;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

.fixATag:hover,
.fixATag:focus {
  color: #9a9a9a;
  text-decoration: underline;
}

.childrenAL {
  margin-top: 10px;
}

.majorListA {
  line-height: 18px;
  font-weight: 500;
  color: $black-color;
  text-decoration: underline;
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
}

.majorListA.highlighted,
.majorListA.highlighted:hover {
    font-weight:bold;
}

a.majorListA.blocked,
span.sub-progs a.blocked {
    color: #bbb;
    text-decoration: none;
}

a.majorListA.blocked:hover,
span.sub-progs a.blocked:hover {
    cursor: default;
    pointer-events: none
}

.majorListA:hover {
  line-height: 18px;
  font-weight: 500;
  color: $uwRed;
  text-decoration: underline;
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
}

.majorListA:active,
.majorListA:focus {
  line-height: 18px;
  font-weight: 500;
  color: $uwRed;
  text-decoration: underline;
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
}

.majorListASelected,
.majorListASelected:active,
.majorListASelected:focus {
  line-height: 18px;
  font-weight: 600;
  color: $uwRed;
  text-decoration: underline;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
}

.pTest {
  font-size: 20px;
  display: inline;
  line-height: 150%;
  margin: 0 1px;
  color: $uwRed;
}

.noBullet {
  margin-top: 15px;
  list-style-type: none;
}

.gradNoBullet {
  list-style-type: none;
}

.underlined {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.text-muted {
  color: #9a9a9a;
}

.text-primary,
.text-primary:hover {
  color: #1d62f0 !important;
}

.text-info,
.text-info:hover {
  color: $info-color !important;
}

.text-success,
.text-success:hover {
  color: $success-color !important;
}

.text-warning,
.text-warning:hover {
  color: $warning-color !important;
}

.text-danger,
.text-danger:hover {
  color: $danger-color !important;
}

/* CUSTOM STYLING FOR HEADERS */
h1 small,
h2 small,
h3 small,
h1 .small,
h2 .small,
h3 .small {
  font-size: 60%;
}

.infoSidebarParagraph {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */
  margin: 10px 50px;

  color: $uwBlue;
}

.infoSidebarParagraph + .infoSidebarParagraph {
  margin-top: 24px
}

.infoSidebarunorderedList {
  max-width: 450px;
  margin: 24px auto 0
}

.infoSidebarunorderedList li {
  color: $uwBlue;
  text-align: left;
}

h1 {
  font-family: brandon-grotesque, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  align-items: center;
  text-transform: uppercase;

  color: $uwDarkGray;
}

h2 , .h2 {
  font-family: brandon-grotesque, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
}

h3 {
  font-family: brandon-grotesque, sans-serif;

  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 150%;

  margin: 0px;

  color: #444444;
}

p + h3, div.row + h3 {
  margin-top: 24px
}

.fontNormal {
  font-weight: normal;
}

.italic {
  font-style: italic;
}

.secondEssayHeader {
  font-family: brandon-grotesque, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 34px;
  align-items: center;

  margin: 20px 0px;
}

.myAccountH3 {
  margin: 20px;
  min-width: 150px;
}

.homepageApplyOnline {
  font-family: brandon-grotesque, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;

  text-align: center;
  text-transform: uppercase;

  color: #444444;
}

.majorText {
  margin: 20px;
  max-width: 700px;
}

.homepageFaqHeader {
  font-family: brandon-grotesque, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.selectiveMajorP {
  font-family: "Open Sans", sans-serif;
  color: black;
  font-weight: bold;
  font-size: 18px;
  max-width: 550px;
  margin: 0px auto 30px;
}

.completed {
  color: darkgreen;
  font-weight: bold;
}

.notCompleted {
  color: $uwRed;
  font-weight: bold;
}

b {
  font-family: "Open Sans", sans-serif;
}

p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 150%;
}

.mnRecip {
  font-size: 14px;
}

.majorListAChild {
  margin: 0px;
}

.bold {
  font-weight: bold;
}

li {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 150%;
}

.transitionLI {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #444444;
}

.activityNote {
  font-style: italic;
  font-size: 14px;
  font-weight: bold;
  line-height: 150%;
}

.secondEssay {
  margin: 20px 0 !important;
}

.onlineProgramKey {
  text-align: center;
}

.essayrte {
    margin-bottom: 6px
}

.prompt + .essayrte {
    margin-top: 10px
}

.essay-last-saved {
  color: $uwBlue;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
}

.infoAlertText {
  margin: 50px 75px;

  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;

  color: $uwDarkGray;
}

.oldApplicationLink {
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;

  text-align: center;
  color: #ffffff;
}

.submitted-header {
  position: relative;
}