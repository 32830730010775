/*     General overwrite     */
.wrapper {
  min-height: 100vh;
  position: relative;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

a {
  color: $info-color;

  &:hover,
  &:focus {
    color: $info-states-color;
    text-decoration: none;
  }
}

.actionColumn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}

a:focus,
a:active,
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0;
}

input[type="file"] {
  margin: 5px 0;
}

.ui-slider-handle:focus,
.navbar-toggle,
input:focus {
  outline: 0 !important;
}

/*           Animations              */
.form-control,
.input-group-addon,
.tagsinput,
.navbar,
.navbar .alert {
  @include transition($general-transition-time, $transition-linear);
}

.sidebar .nav a,
.table > tbody > tr .td-actions .btn {
  @include transition($fast-transition-time, $transition-ease-in);
}

.btn {
  @include transition($ultra-fast-transition-time, $transition-ease-in);
}

.fa {
  width: 18px;
  text-align: center;
}

.margin-top {
  margin-top: 50px;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.inline {
  display: inline !important;
}

.aTag {
  color: black;
}

.center {
  margin: 0 auto;
}

.slidedown:hover {
  background-color: white;
}

.close {
  margin: -15px 0px !important;
  font-size: 50px;
  color: $uwRed;
  opacity: 1;
}

.imgBlock {
  margin: 0 auto;
  display: block;
}

.modalMinHeight {
  padding-left: 50px;
  min-height: 250px;
}

.noMarginBtn {
  margin-left: 0px !important;
}

.control-label {
  font-size: 16px !important;
}

.pDashboard {
  display: inline;
  font-size: 14px;
  padding: 5px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}

.react-confirm-alert-overlay {
  background: rgba(255, 255, 255, 0.1) !important;
  backdrop-filter: blur(1px);
}

.react-confirm-alert {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #cccccc;
}

.react-confirm-alert ul {
  text-align: left
}

.test {
  border: 1px solid red;
}

.googleRecaptchaContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.chooseMajorProgramContainer {
  width: fit-content;
}

.couponColumn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.actionsColumn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.couponIButton {
  width: 20px;
  height: 20px;
}

.paidColumn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.feePaidText {
  margin: 8px auto 0;
  font-size: 14px;
  line-height: 18px
}

.couponButton {
  height: 25px;
  max-width: 85px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin: 10px;

  color: white !important;
  background-color: $uwRed !important;
  border-color: $uwRed !important;
  opacity: 1;
}

.couponButton > p {
  font-size: 14px;
  margin: 0 auto;
}

.btn.couponButton:disabled::after {
    content: ''
}

.btn.couponButton.btn-remove {
    max-width: 130px;
    margin-right: 0
}

.card .table tbody td:last-child.not-padded {
    padding: 12px 0;
}

.maxWidthCoupon {
  max-width: 100px;
  min-width: 50px;
}

.chooseAddressContainer {
  background-color: #f5f5f5;
  border: 1px solid darkgrey;
  border-radius: 25px;
}

.chooseAddressContainer h3 {
  padding-bottom: 6px
}

.chooseAddressContainer > div.text-center {
  padding: 3px 0
}

.chooseAddress {
  color: black;
  font-size: 18px;
}

.chooseAddress:hover {
  cursor: pointer
}

.modal-dialog {
  width: 100vw;
  padding: 0 10px;
  max-width: 1200px
}

.modal-dialog img.uws-logo {
  width: 80%;
  max-width: 750px
}

.rowMargin {
  margin: 25px 0;
}

.rowMargin h5 {
  font-weight: 700
}

.rowMargin .table thead tr th {
  font-weight: bold
}

.rowMargin a {
  color: #3a3a3a
}

.rowMargin a:hover {
  cursor: pointer;
}

.completion-percentage {
  color: #2e8540;
  font-weight: 600
}

.tab-wrapper {
  position: relative;
  height: 41px;
  display: block;
  margin-top: 24px;
}

.tab-header {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(50% + 8px);
  z-index: 1;
  height: 100%;
  display: inline-block;
  text-align: center;
  border-radius: 12px 12px 0 0;
  border: 1px solid #ccc;
  font-family: brandon-grotesque, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  color: #666;
  line-height: 20px;
  padding: 12px 0 8px;
  background-color: #FAF9F6
}

.tab-header + .tab-header {
  left: unset;
  right: 0
}

.tab-header.active {
  color: uwBlue;
  background-color: #fff;
  font-size: 15px;
  width: calc(50% + 8px);
  z-index: 2;
  border-bottom: unset
}

.tab-header:not(.active):hover {
  cursor: pointer;
}

div.tabs {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-radius: 0 0 12px 12px;
  padding: 8px 12px 0;
  margin-bottom: 24px;
  display: block;
}

div.tabs .btn-wrapper {
  padding-bottom: 8px;
  width: 100%;
}

div.tabs .btn-wrapper .btn-general {
  margin: 8px 0 0 auto;
  display: block;
  width: 300px;
  background-color: $uwRed;
  border-color: $uwRed;
}

p.da-explain {
  text-align: center;
  padding: 24px 0 12px
}

@media (max-width: 600px) {
  div.tabs .btn-wrapper .btn-general {
      margin-right: auto
  }
}

@media screen and (max-width: 1185px) {
  div.tabs .btn-wrapper .btn-general {
    font-size: 14.5px;
    line-height: 18.5px;
    height: 40px
  }
}

.myAccountRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.disabledInput {
  font-weight: 600 !important;
  font-style: italic;
}

.invalidApplication {
  border: 2px solid $uwRed;
}

.marginLeft {
  margin-left: 20px;
}

.practice-homepage {
  color: yellow;
  font-size: 28px;
  font-weight: bold;
}

.pay-date-past-button {
  margin-left: 30px;
  font-weight: bold;
  max-width: 300px;
}

.box {
  padding: 10px;
  border: 1px solid grey;
  width: 90%;
  max-width: 800px
}

*:focus:not(.focus-visible) {
  outline: none;
}

/* Optional: Customize .focus-visible */
.focus-visible {
  outline: lightgreen solid 2px;
}

.cashnet-small-device {
  color: $uwRed;
  font-size: 14px;
}

.helpContainer {
  position: fixed;
  bottom: 60px;
  right: 0;
  z-index: 999;
  margin: 10px 20px;
}

.helpImage {
  display: block;
  width: 55px;
  height: 55px;
}

.majorSelectedContainer {
    text-align: left;
    display: flex;
    flex-direction: row;
}

.majorSelectedContainer > div:first-child {
    padding-right: 18px
}

.majorSelectedContainer h3 {
    font-size: 20px;
}

.majorSelectedContainer h3 + h4 {
    font-size: 14px;
    font-family: brandon-grotesque, sans-serif;
    text-transform: uppercase;
    margin-top: 0;
}

.programSelected {
  margin-top: 30px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.clickable:focus,
.clickable:hover,
.clickable:active {
  color: $uwLightBlue;
}

.multiCampusSummary {
  color: $uwRed;
}

.helpTextOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -20px !important;
  //right: 200px !important;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: $uwLightBlue;
  border-radius: 999rem;

  //transition: .5s ease-in-out;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.helpContainer:hover .helpTextOverlay {
  opacity: 1;
  width: 100px;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
}

.helpText {
  color: white;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  white-space: nowrap;
}

[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}

[style*="--aspect-ratio"] > img {
  height: auto;
}

@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    height: 0;
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.nextStepsDownloadPDF {
  display: inline-block;
  //margin-left: auto;
  //margin-right: 20px;
}

.selectedRadioContainer {
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  //flex-wrap: wrap;
  //align-items: center;
  justify-content: left;
}

.selectedRadioContainer div.radio {
    display: block;
    line-height: 24px;
    margin-top: 12px
}

.selectedRadioContainer .w-help-text {
    display: flex;
    flex-direction: row;
}

.selectedRadioContainer .w-help-text div.radio {
    display: inline-block
}

.selectedRadioContainer .w-help-text > a {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.selectedRadioContainer .w-help-text > a img {
    margin-top: 0
}

.selectedRadioContainer span.sub-progs {
    padding: 0 0 4px 8px;
    border-left: 6px solid #eee;
    margin: 6px 0 4px 14px
}

.selectedRadioContainer span.sub-progs div {
    margin-top: 8px
}

.selectedRadioContainer span.sub-progs div:first-child {
    margin-top: 4px
}

.selectedRadioContainer span.sub-progs label {
    line-height: 20px
}

.radioPrimaryElement {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-left: 40px;
}

.radioSecondaryElement {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-left: 40px;
}

.openingPageContainer {
  margin: 20px 50px;
}

.ccHeadRadio {
  margin: 0 !important;
  margin-left: 20px !important;
  display: block;
  width:100%
}

.ccRadioContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;

  margin-left: 50px;
}

.marginBottom {
  margin-bottom: 15px;
}

.nextStepsCampus {
  padding: 20px !important;
  background-color: $uwLightGray;
  border-radius: 10px;

  margin: 20px 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.nextStepsText {
  margin: 0px;
  margin-bottom: 20px;

  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 145%;
  color: black;
}

.block {
  display: block !important;
}

.clearProgram {
  margin-left: 10px;
}

.orUndecided {
  margin: 20px 10px;
}

.ccContainer {
  border: 1px solid $uwBlue;
  min-height: 60px;

  align-items: center;
  justify-content: left;
  text-align: left;

  margin: 10px;
  border-radius: 10px;
  position: relative;
  padding: 15px 15px 15px 10px
}

.ccContainer > div,
.ccContainer > span,
.ccContainer span.sub-progs div {
    display:block;
}

.ccContainer > div.radio {
    margin:8px 0 0 48px
}

.ccContainer span.sub-progs {
    padding: 0 0 4px 8px;
    border-left: 6px solid #eee;
    margin: 6px 0 4px 52px
}

.ccContainer span.sub-progs div {
    margin-top: 8px
}

.ccContainer span.sub-progs div:first-child {
    margin-top: 4px
}

.ccContainer span.sub-progs label {
    line-height: 20px
}

.ccContainer .radioPrimaryElement {
    display: block;
    position: relative;
}

.azContainer {
  text-align: left;
}

.alphabeticalMenu {
  margin-bottom: 40px;
  text-align: center
}

.alphabeticalMenu a {
    text-align: center;
}

.alphabeticalMenu a:not(.underlined):hover {
    cursor:pointer
}

.alphabeticalMenu a {
    padding: 0 10px;
}

.alphabeticalMenu a:not(:first-child) {
    border-left: 1px solid $uwRed
}

.azContainer > a,
.azContainer .sub-progs a {
    display: block
}

.azContainer > a {
    padding: 12px 0
}

.azContainer .sub-progs {
    margin: 4px 0 18px 24px;
    padding-left: 18px;
    border-left: 6px solid #eee;
    display: block;
}

.azContainer .sub-progs a {
    color: #000;
    text-decoration: underline;
    padding: 5px 0;
    font-size: 16px
}

.maxWidth {
  margin: 0 50px;
}

.my-account-start-app-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  padding: 0 25px;
}

.my-account-start-app-row input[type="button"].btn-general {
  margin-left: auto;
  width: 300px;
  background-color: $uwRed;
  border-color: $uwRed;
}

.unpaid-warning {
  display: block;
  margin-top: 4px;
  color: $uwRed;
  font-weight: 500;
  font-size: 13.5px;
}

ul.my-docs li {
  line-height: 175%
}

ul.my-docs li a {
  font-weight: bold;
}

ul.my-docs li a:hover {
  cursor: pointer;
}

.floatRight {
  float: right;
}

.confirm-delete-prompt {
    padding: 12px 24px 18px
}

.card-HOLISTIC ul li {
    padding:8px 0 4px
}

button.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #1DC7EA;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px
}

button.link-button.update-pwd {
    margin: 12px 0;
}

button.link-button.offset-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 60px 0 0 auto;
    padding-top: 6px;
    border-top: 6px solid #eee;
}

div.block-create {
  max-width: 750px;
  padding: 0 10%;
}

div.app-overview {
  position: relative;
  display: block;
  max-width: 632px;
  margin: 0 auto;
  padding: 18px 0
}

div.app-overview > img {
  position: absolute;
  left: 0;
  top: calc(50% - 30px)
}

div.app-overview p {
  padding-left: 84px;
  padding-right: 0
}

div.app-overview hr {
  margin: 0 0 10px 84px
}

div.app-overview p:last-child {
  margin-bottom: 0
}

div.app-overview table {
  width: 100%;
  margin-top: 48px
}

div.app-overview table td:first-child {
  background-color: $uwBlue;
  color: #fff;
  font-weight: bold;
}

div.app-overview table tr:not(:last-child) td:first-child {
  border-bottom: 1px solid #fff
}

div.app-overview table td:nth-child(2) {
  text-align: left;
}

div.app-overview table tr:nth-child(2n) td:nth-child(2) {
  background-color: #eee;
}

div.app-overview table tr:first-child td:first-child {
  border-top-left-radius: 10px
}

div.app-overview table tr:last-child td:first-child {
  border-bottom-left-radius: 10px
}

div.app-overview table tr:first-child td:nth-child(2) {
  border-top-right-radius: 10px
}

div.app-overview table tr:last-child td:nth-child(2) {
  border-bottom-right-radius: 10px
}

div.app-overview.is-submit {
  margin: 12px 0 24px 12px;
}

div.app-overview.is-submit-DA {
  max-width: unset;
  padding: 0;
}

div.app-overview.is-submit-DA p {
  margin: 18px 0 0 24px;
  border-left: 5px solid $uwBlue;
  padding: 0 0 0 12px
}

div.app-overview.is-submit-DA table {
  margin: 24px auto 0;
  max-width: 632px
}

div.direct-admit-autofills {
  display: inline-block;
  width: 80%;
  max-width: 600px;
  margin-left: 5%;
  padding: 0
}

div.direct-admit-autofills div.checkbox {
  display: block;
  text-align: left;
  padding-top: 18px
}

table.app-summary {
  margin: 24px 0;
  width: 100%
}

table.app-summary,
table.app-summary tr,
table.app-summary td.app-section {
  border-bottom: 1px solid #fff;
  border-collapse: separate;
}

td.app-section {
  position: relative;
  background-color: $uwBlue;
  color: #fff;
  font-weight: 700;
  font-size: 120%;
}

td.app-section:hover {
  cursor: pointer
}

table.app-summary tr:first-child td.app-section {
  border-radius: 8px 8px 0 0
}

td.app-section span {
  position: absolute;
  right: 24px
}

table.app-summary td {
  text-align: left;    
}

table.app-summary td:first-child:not([colSpan='2']) {
  max-width: 275px
}

table.app-summary td:nth-child(2n),
table.app-summary td[colSpan='2'] {
  font-weight: 700
}

table.app-summary tr:nth-child(2n+1) td:not([colSpan='2']):not(.app-section) {
  background-color: #eee
}

table.app-summary td.header-2 {
  background-color: $default-color;
  color: #fff
}

table.app-summary td.header-3 {
  padding-left: 48px;
}

table.app-summary td.header-3::before {
  content: '\25CF';
  margin-right: 4px
}

table.app-summary td.essay {
  padding-left: 1.5em;
  padding-right: 1.5em;
}

table.app-summary td.essay,
table.app-summary td.essay p,
table.app-summary td.essay ol,
table.app-summary td.essay ul {
  font-size: 14.5px
}

table.app-summary a {
  color: $new-dark-blue;
  font-weight: 700;
}

table.app-summary a:hover {
  cursor: pointer;
}

table.app-summary a img {
  margin-right: 6px
}

p.eau-msg {
  padding: 0 36px;
  margin: 0 auto;
  max-width: 650px;
}

a.pfa-home-link {
  color: $uwRed
}

a.pfa-home-link:hover {
  cursor: pointer
}

@media screen and (max-width: 600px) {
  table.app-summary tr {
    margin-bottom: 0;
    padding: 0
  }

  table.app-summary td:first-child:not([colSpan="2"]) {
    max-width: unset;
    padding-bottom: 0;
  }
}

.card table.reciprocity-main thead th:first-child,
.card table.reciprocity-main tbody td:first-child {
  max-width: 269px
}

.card table.reciprocity-main tbody td:first-child {
  padding-left: 8px
}

.card table.reciprocity-main tbody td:first-child button:first-child {
  margin-right: 12px
}

@media screen and (max-width: 1325px) {
  .card table.reciprocity-main thead th:first-child,
  .card table.reciprocity-main tbody td:first-child {
    max-width: 156px
  }

  .card table.reciprocity-main tbody td:first-child button:first-child {
    margin-right: 0;
    margin-bottom: 8px
  }
}

.campus-select-column {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

.campus-select-column .col-md-12 + .col-md-12 {
  margin-top: 24px
}

.save-btn.btn-recip-list,
.save-btn.btn-recip-list:hover,
.save-btn.btn-recip-list:focus,
.save-btn.btn-recip-list:active {
  height: unset;
  display: block;
  margin: 16px auto;
  font-size: 14px;
  padding: 6px 16px
}

.recip-list {
  font-size: 95%;
  line-height: 1.4em;
  margin: 18px 0 18px 12px;
  border-left: 6px solid $uwBlue;
  padding-left: 12px;
  min-height: 60px
}

.recip-list.higher-ed {
  margin: 0 0 0 12px
}

.recip-list span,
.recip-list a {
  display: block;
  margin-bottom: 4px
}

.recip-list a {
  font-weight: 700;
}

.recip-list a:hover {
  cursor:pointer
}

.mn-recip-submitted {
  margin: 0 0 36px 12px;
  border-left: 6px solid $uwBlue;
  padding-left: 12px; 
}

.mn-recip-record {
  background-color: #e1e1e1;
  border-radius: 12px 12px 6px 6px;
  padding-bottom: 8px;
  margin-bottom: 18px
}

.mn-recip-record div:first-child {
  background-color: $uwBlue;
  color: #fff;
  border-radius: 12px 12px 0 0;
  padding: 8px 18px 0;
  position: relative;
  min-height: 62px
}

.mn-recip-record div[data-submitted='false']:first-child p,
.mn-recip-record div[data-submitted='false']:first-child a {
  margin-top: 10px
}

.mn-recip-record div:first-child p {
  font-weight: 700;
}

.mn-recip-record div:first-child p span {
  display: block;
  font-size: 85%;
  text-transform: uppercase;
}

.mn-recip-record div:first-child a {
  color: #fff;
  text-align: right;
  position: absolute;
  top: 15px;
  right: 18px;
  font-size: 85%;
  line-height: 1.25em;
  font-weight: 700;
  text-decoration: underline;
  text-transform: uppercase;
}

.mn-recip-record div:first-child a:hover {
  cursor: pointer;
}

.mn-recip-record > span {
  display: block;
  padding: 12px 24px 0
}

.higher-ed-row + h2 {
  margin-top: 36px
}