.sidebar {
  position: relative;
  margin-top: $screen-top-margin;
  margin-right: $sidebar-right-margin;
  width: $sidebar-width;

  font-weight: 200;
  background-size: cover;
  background-position: center center;

  .sidebar-wrapper {
    position: relative;
    color: #fff;
    background-color: #f8f8f8;
    overflow: hidden;
    width: $sidebar-width;
    z-index: 4;
    padding-bottom: 30px;
    border-radius: $border-radius;
  }

  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: #ff0000;
  }

  .logo {
    padding: 10px 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    z-index: 4;

    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: $white-color;
      line-height: 20px;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    a.logo-mini {
      float: left;
      text-align: center;
      width: 30px;
      margin-right: 15px;

      img {
        width: 40px;
        margin-left: -3px;
        display: block;
        margin-top: 2px;
      }
    }

    a.logo-normal {
      display: block;
    }

    .logo-img {
      width: 34px;
      display: inline-block;
      height: 34px;
      margin-left: -2px;
      margin-top: -2px;
      margin-right: 10px;
      border-radius: 30px;
      text-align: center;
    }
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .nav {
    margin-top: 20px;

    li {
      > a {
        color: #000;
        opacity: 0.86;
        //border-radius: 4px;
        //padding: 5px 5px;
      }

      .nav-link {
        width: 100%;
        padding: 0px 20px;
        display: inline-block;

        &.active .sidebarRouteName {
          font-weight: bold;
        }
      }

      .nav-link:focus {
        color: #000;
        opacity: 1;
        background: #fff;
        //-webkit-box-shadow: 0px 1px 3px 2px rgba(0,0,0,0.5);
        //-moz-box-shadow: 0px 1px 3px 2px rgba(0,0,0,0.5);
        //box-shadow: 0px 1px 3px 2px rgba(0,0,0,0.5);
      }

      &:hover > a,
      &.open > a,
      &.open > a:focus,
      &.open > a:hover {
        background: #fff;
        opacity: 1;
      }

      &.active > a {
        color: #000;
        opacity: 1;
        //background: #fff;
        //-webkit-box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.2);
        //-moz-box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.2);
        //box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.2);
      }

      &.disabledNav > a {
        color: #000;
        opacity: 0.6;
        background: $light-gray;
      }
    }

    p {
      margin: 0;
      line-height: 30px;
      font-size: 14px;
      font-family: "Open Sans", sans-serif;
    }

    .caret {
      top: 24px;
      position: absolute;
      right: 15px;
    }

    i {
      font-size: 28px;
      float: left;
      margin-right: 15px;
      line-height: 30px;
      width: 30px;
      text-align: center;
    }
  }
}

.sidebarHeader {
  min-height: 100px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.sidebar-wrapper h3 {
  color: $uwBlue;
  text-align: center;
  margin-top: 20px;
}

.sidebarLogo {
  width: 200px;
}

.sidebar,
body > .navbar-collapse {
  .logo {
    padding: 10px 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: $white-color;
      line-height: 20px;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .simple-text {
      text-transform: uppercase;
      padding: $padding-small-vertical $padding-zero;
      display: block;
      font-size: $font-size-large;
      color: $white-color;
      font-weight: $font-weight-normal;
      line-height: 30px;
    }
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  &:after,
  &:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &[data-color="blue"]:after {
    @include icon-gradient($new-dark-blue, $blue-color-bottom);
  }

  &[data-color="azure"]:after {
    @include icon-gradient($new-blue, $azure-color-bottom);
  }

  &[data-color="green"]:after {
    @include icon-gradient($new-green, $green-color-bottom);
  }

  &[data-color="orange"]:after {
    @include icon-gradient($new-orange, $orange-color-bottom);
  }

  &[data-color="red"]:after {
    @include icon-gradient($new-red, $red-color-bottom);
  }

  &[data-color="purple"]:after {
    @include icon-gradient($new-purple, $purple-color-bottom);
  }
}

.noSidebarHeaderContainer {
  margin: 50px 100px 0 100px;
  text-align: center;
  width: 100%;
}

.noSidebarContent {
  margin-top: 50px;
}

p.degree-seeking-expl {
    padding: 0 18px;
    margin-bottom: 50px;
    max-width: 600px
}

.fullWidth {
  width: 100%;
}

.noSidebarWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.noSidebarPanel {
  margin-top: 100px;
  max-width: 1080px;
  min-width: 800px;
  border-radius: 10px;
}

.noSidebarCard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  border: none !important;
  height: 100%;
}

.noSidebarCard.no-spacing .noSidebarContent {
    margin-top: 0
}

.main-panel {
  position: relative;
  width: 1000px;
  z-index: 10;
  margin-top: $screen-top-margin;
  margin-bottom: $screen-bottom-margin;
  display: inline-block;
  border-radius: $border-radius;

  > .content {
    padding: 50px;
    min-height: calc(100% - 61px);
    //max-height: calc(100vh - 61px);
    overflow-y: auto;
  }

  > .footer {
    border-top: 1px solid #e7e7e7;
  }

  .navbar {
    display: none;
    margin-bottom: 0;
    background-color: $uwBlue;
  }
}

.sidebar,
.main-panel {
  -webkit-transition-property: top, bottom;
  transition-property: top, bottom;
  -webkit-transition-duration: 0.2s, 0.2s;
  transition-duration: 0.2s, 0.2s;
  -webkit-transition-timing-function: linear, linear;
  transition-timing-function: linear, linear;
  -webkit-overflow-scrolling: touch;
}

.card {
  box-shadow: none !important;
  border-top: 1px solid white;
}

.card-PAYMENT .cardContent {
  margin: 0 !important;
}

.card-PAYMENT .cardHeader {
  margin: 20px 30px !important;
}

.application-payment-margin {
  margin: 10px 30px;
}

.nav-dot {
  height: 10px;
  width: 10px;
  background-color: transparent;
  border: 1px solid $uwDarkRed;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.sidebarIcon {
  display: inline-block;
  margin-top: -4px;
  margin-right: 10px;

  height: 18px;
  width: 18px;
}

.sidebarSpacer {
  margin: 0px 25px;
  background: $uwBlue;
  border: 0.1px solid #c6c6c6;
  box-sizing: border-box;
}

.sidebarRouteName {
  display: inline-block;
  font-family: "Open Sans", sans-serif;

  color: black;

  line-height: 30px;
  font-size: 14px;
}

.sidebarRouteDropdown {
  margin-left: 4px !important;
}

.ulnav {
  padding-left: 15px;
  position: relative;
  display: block;
  list-style: none;
}

.ulnav > li {
  //padding: 5px 5px;
}

.logo img {
  width: 100%;
  padding-top: 15px;
}

.nav-border {
  border-bottom: 1px solid #ccc;
}

.navBarLogo {
  height: 60px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.headerLogo {
  margin-left: 40px;
  display: inline;
}

.centerVertical {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.headerText {
  direction: rtl;
  float: right;
  padding-right: 50px;
  padding-top: 10px;

  color: $uwRed;
  font-weight: 500;
  font-size: 20px;
  font-family: brandon-grotesque, sans-serif;
}

.first-panel {
  background: #fff;
  position: relative;

  > .content {
    //min-height: calc(100% - 61px);
    //max-height: calc(100vh - 61px);
    overflow-x: hidden;
  }
}

.progress-bar {
  height: 20px;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  margin: 20px 0px;
}

.progressText {
  display: inline;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #ffffff;
}

.progressPercent {
  display: inline;
  float: right;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #ffffff;
}

.filler {
  background: $uwRed;
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
}

.cashnet-frame {
  width: 100%;
  height: 650px;
}

.inlineBlock {
  display: inline !important;
}

.floatRight {
  float: right;
}

.loadAppMode {
  margin-bottom: 40px;
}

.arrowDown {
  margin-top: 10px;
  float: right;
}

.arrowRight {
  transform: rotate(-90deg);
  margin-top: 10px;
  float: right;
}

.sidebarDropdownUL {
  list-style-type: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.homepageContainer {
  //max-width: 1800px;
  width: 100%;

  overflow-x: hidden;
  margin: 0px !important;
  float: none !important;
  background-color: transparent;
}

.homepageHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 30px;
  height: 100px;

  & > .homepageHeaderImg {
    margin-left: 100px;
  }

  & > .homepageHeaderLinksContainer {
    margin-left: auto;
    margin-right: 100px;

    & > .homepageLinks {
      margin: 10px;
      display: inline-block;
      color: white;
      font-family: brandon-grotesque, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;

      &:hover {
        color: $uwLightBlue;
      }
    }
  }
}

.beginJourneyRow {
  margin-top: 60px;
}

.leftColumnBeginJourney {
  height: 460px;
  margin-right: 40px;

  top: -70px;
}

.homepageBeginJourneyBlock {
  background-color: white;
  //margin-right: 100px;
  border-radius: 8px;

  padding: 20px;
}

.beginYourJourney {
  display: flex;
  justify-content: center;
  text-align: center;

  margin: 10px;
}

.fill-height {
  height: 100%;
}

.bgWhite {
  background-color: white;
}

.homepageStudentsRatio {
  min-height: 600px;
}

.marginTop {
  margin-top: 50px;
}

.visaText {
  font-size: 20px;
  color: $uwDarkGray;
  text-align: left;
  font-family: brandon-grotesque, sans-serif;

  margin: 10px 30px;
}

.visaContainer {
  background-color: white;
  border-radius: 8px;
  border: 1px solid $uwBlue;
  margin: 20px;
  cursor: default;
}

.visaContainer:focus,
.visaContainer:hover,
.visaContainer:active {
  -webkit-box-shadow: inset 0px 1px 10px 1px rgba(0,0,0,0.25);
  -moz-box-shadow: inset 0px 1px 10px 1px rgba(0,0,0,0.25);
  box-shadow: inset 0px 1px 10px 1px rgba(0,0,0,0.25);
}

.begin {
  color: $uwBlue;
  font-family: brandon-grotesque, sans-serif;
  font-weight: normal;
  font-size: 55px;
  display: inline-block;

  margin: 5px;
}

.yourJourney {
  color: $uwRed;
}

.homepageH3 {
  font-family: brandon-grotesque, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  text-align: center;
  color: #444444;
  margin: 10px;
}

.faqHomepage {
  margin: 20px 10px;
}

.homepagenav-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  margin: 10px;
}

.homepageButtonBox {
  height: 70px;
  width: 40%;
  margin: 15px;
  border-radius: 8px;
  min-width: 250px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $uwLightBlue;
  }
}

.uwRed {
  background-color: $uwRed;
}

.uwBlue {
  background-color: $uwBlue;
}

.homepageButtonIcons {
  width: 70px;
  height: 70px;
}

.homepageText {
  font-weight: bold;
  font-size: 16px;
  color: white;
  text-align: center;
  margin: 0 10px 0 0px;
}

.homepageH5 {
  font-family: brandon-grotesque, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;

  text-align: center;
  text-transform: uppercase;

  color: #444444;

  margin: 10px;
}

.loginHere {
  color: $uwRed;
  text-decoration: underline;

  &:hover {
    color: $uwLightBlue;
  }
}

.announcementBar {
  margin-top: 100px;
  min-height: 100px;
  background-color: $uwRed;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.announcementBarText {
  font-weight: bold;
  font-size: 22px;
  color: white;
  text-align: center;
  margin: 20px;
}

.imageTest {
  border: 2px solid sienna;
  width: inherit !important;
}

.homepageFooter {
  min-height: 200px;
  background-color: $uwBlue;

  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.homepageFooterTop {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  & > .footerImg {
    margin-left: 150px;
  }

  & > .footerLinksContainer {
    margin-left: auto;
    margin-right: 100px;

    & > .footerLinks {
      margin: 0 30px;
      color: white;
      font-family: brandon-grotesque, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;

      &:hover {
        color: $uwLightBlue;
      }
    }
  }
}

.footerSpacer {
  margin: 20px 100px;
  border: 1px solid #f6f6f6;
}

.footerCopyright {
  margin-left: 100px;

  font-family: brandon-grotesque, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: white;
}

.flag-select {
  margin: 0 30px;
}

.flag-select__btn {
  font-weight: bold;
  font-size: 18px !important;
  color: white !important;
}

.infoSidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 0%;

  max-height: none;
  margin-right: 5%;
  padding-right: 0%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.infoSidebarWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  flex-wrap: wrap;

  background-color: white !important;
}

.infoSidebarItems {
  margin: 15px 30px;

  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.infoSidebarHeader {
  background-color: $uwDarkBlue;
  width: 100%;
  height: 100px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;

  align-self: flex-start;

  border-radius: 10px 10px 0 0;
}

.infoSidebarLogo {
  max-width: 200px;
}
