/* UW HELP CUSTOM STYLESHEET */
/* GLOBAL */
 a:focus, button:focus, input:focus, .submit-button:focus, .checkbox input:focus, .initialInfoCard:focus {
     outline-style: solid !important;
     outline-width: 3px !important;
     outline-color: #6699cc !important;
     cursor:pointer;
}
.radio input:focus {
  outline-offset: -10px !important;
  box-shadow: 0px 0px 8px #0066FF !important;
  cursor:pointer;
}
 .sidebar-wrapper ul .nav-link:focus {
     background-color:#ccc !important;
}
 .optional-one-off {
     color: rgb(56, 105, 161)
}
 .announcement {
     margin:20px;
}
 .select__multi-value, .select__indicator {
     cursor:pointer;
}
 #high_school_transcript {
     position: relative;
     top:8px;
}
 .card-ESSAY .rtf-wrapper div:nth-child(2) {
     position: absolute;
     top: 70px;
     left: 0;
     height:85% !important;
     width: 100%;
     padding:10px;
     overflow-y: scroll;
}
 .react-confirm-alert-body {
     padding:40px;
     text-align: center
}
 .react-confirm-alert-button-group button {
     width:100%;
     text-align: center;
     font-weight:bold;
     font-size:13px;
     padding:10px;
}
 .react-confirm-alert-button-group button:nth-child(1):hover {
     background-color: #0074FF;
}
 .react-confirm-alert-button-group button:nth-child(2):hover {
     background-color: #D44427;
}
/* INITIAL, SELECT MODIFIER, SELECT MAJOR */
 .initialInfoCard, .form-group .form-control, .form-group .form-control option, .visaContainer p, .radio input {
     cursor:pointer;
}
/* ACADEMIC BACKGROUND */
 #hsForm table td {
     cursor:pointer;
}
 #high_school_transcript:focus, #college_transcript:focus, #employment_resume_file:focus {
     outline-style: solid !important;
     outline-width: 3px !important;
     outline-color: #6699cc !important;
}
 .school_select {
     width:100%;
     padding:4px;
     border-style:solid;
     border-width:0px;
     border-color:#FFF;
     margin-bottom:5px;
}
 .school_select:hover, .school_select:focus {
     background-color:#CECECE;
}
 .school_not_found {
     margin-top:10px;
     padding-top:12px;
     padding-bottom:12px
}
/* NEXT STEPS */
 .survey-wrap {
     width:100%;
}
 .nextStepsCampus .survey-wrap {
     display:none;
}
 .nextStepsCampus:last-child .survey-wrap:last-child {
     display:block;
}
 .nextStepsCampus {
     padding-top:-30px;
}
 .survey-click {
     margin-top:50px;
     cursor:pointer;
     text-align: center;
     background-color:#fff;
     padding:20px;
     border-radius: 8px;
}
 .survey-click .survey-icon, .survey-click h3 {
     display:inline-block;
}
 .survey-click .survey-icon {
     position: relative;
     left:-5px;
     top:-3px;
     width:28px;
}
 .survey-click h3 {
     margin-left:10px;
     font-family: "Open Sans", sans-serif;
     font-style: normal;
     font-size: 14px;
}
 .feedback-link {
     text-decoration:underline;
}
 .survey-click:hover h3 {
     color: #005777;
}
 .survey-click:hover {
     background-color: #f7f7f7;
}
 .next-steps-wrap {
     background-color:#eee;
     overflow: auto;
     border-radius: 8px;
     margin-top: 36px
}
 .term-search-result {
     margin: 0 0 12px 24px
}
 .term-search-result+h3 {
     margin-top: 32px
}
.create-acct {
    padding-top: 36px
}
div.yt-container-congrats {
     text-align: center;
     margin: 32px 0;
}
tr.app-fee-total td:first-child {
    text-align: right;
    font-weight: bold;
    padding-right: 24px
}
tr.app-fee-total td:last-child {
    font-weight: bold;
    font-size: 125%
}

/*  Direct Admit  */
.main-content {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    background-color: #fff;
    display: block;
    padding: 40px 
}

.tabs h4 {
    font-weight: 700;
    margin-top: 0
}

.tabs h4 + p {
    font-size: 90%
}

.direct-admit-upcoming-note {
    max-width: 420px;
    margin: 0 auto 10px
}

@media (max-width: 600px) {
    .tabs h4 {
        margin-top: 24px
    }
}

@media (min-width: 1440px) {
    .main-content {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
}

/* MEDIA QUERIES */
 @media (min-width: 600px) {
     .majorSelectedContainer .deleteBtn {
         position: relative;
         top:5px
    }
}
 @media (max-width: 991px) {
     .__react_component_tooltip {
         width:80%;
         margin-left:30% !important;
    }
}
 @media (max-width: 599px) {
     .initialInfoCardText p {
         font-size-adjust: .5;
         line-height: 18px !important;
    }
     .editBtn img {
         margin:0
    }
     .__react_component_tooltip {
         width:80%;
        /*! margin-left:20% !important;
         */
    }
     .card-RESIDENCY .cardContent .btn-info {
         position: relative;
         top:-14px;
         left:10px;
    }
}
 @media (max-width: 400px) {
     .customRadioContainer label {
         max-width: 300px
    }
     .majorSelectedContainer .deleteBtn, .majorSelectedContainer h2 {
         padding-left:20px;
    }
     .majorSelectedContainer .deleteBtn {
         position: relative;
         top:0px;
         margin-bottom:5px;
    }
     .majorSelectedContainer h2 {
         position: relative;
         top:-8px;
    }
     .modal-footer .btn-info {
         width:100% !important;
    }
     .card form {
         padding-left:10px;
         padding-right:10px;
    }
     .card-SIGN .actionsColumn {
         float:left;
    }
     #tooltip img {
         margin-top:-5px;
    }
     .tooltipContainer {
         width:80%;
         margin-left:0px !important;
    }
     .tooltipContainer p {
         font-size: 11px;
         line-height: 11px;
    }
     .card-SIGN .deleteBtn {
         position: relative;
         left:50%;
         padding-bottom:10px
    }
     .card-SIGN td:nth-child(5):before {
         width:100%;
         text-align: center;
    }
     .card-SIGN tbody {
         background-color: #f9f9f9;
    }
     .card-SIGN .table tr {
         padding-bottom:30px;
         background-color: #f9f9f9;
  }
}
