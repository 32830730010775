//Small device breakpoint
@media (min-width: 992px) {
  .navbar-form {
    margin-top: 21px;
    margin-bottom: 21px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .navbar-nav > li > .dropdown-menu,
  .dropdown .dropdown-menu {
    @include transform-scale(0);
    @include transition($slow-transition-time, $transition-bezier);
  }
  .navbar-nav > li.open > .dropdown-menu,
  .dropdown.open .dropdown-menu {
    @include transform-scale(1);
    @include transform-origin($dropdown-coordinates);
  }

  .navbar-nav > li > .dropdown-menu:before {
    border-bottom: 11px solid rgba(0, 0, 0, 0.2);
    border-left: 11px solid rgba(0, 0, 0, 0);
    border-right: 11px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    position: absolute;
    left: 12px;
    top: -11px;
  }
  .navbar-nav > li > .dropdown-menu:after {
    border-bottom: 11px solid #ffffff;
    border-left: 11px solid rgba(0, 0, 0, 0);
    border-right: 11px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    position: absolute;
    left: 12px;
    top: -10px;
  }

  .navbar-nav.navbar-right > li > .dropdown-menu:before {
    left: auto;
    right: 12px;
  }

  .navbar-nav.navbar-right > li > .dropdown-menu:after {
    left: auto;
    right: 12px;
  }

  .footer:not(.footer-big) {
    nav > ul {
      li:first-child {
        margin-left: 0;
      }
    }
  }

  body > .navbar-collapse.collapse {
    display: none !important;
  }

  .card {
    form {
      [class*="col-"] {
        padding: 6px;
      }

      [class*="col-"]:first-child {
        padding-left: 15px;
      }

      [class*="col-"]:last-child {
        padding-right: 15px;
      }
    }
  }

  .homepageSidebar {
    display: none;
  }

  .table-responsive {
    overflow: visible;
  }
}

@media (max-width: 991px) {
  .initialInfoCardText > p {
    font-size: 16px !important;
    line-height: 1.1;
  }

  //Fixes tablets
  .wrapper {
    min-height: auto;
  }

  .maxWidth {
    margin: 0 10px;
  }

  .sidebar-wrapper,
  .infoSidebarHeader {
    border-radius: 0 !important;
  }

  .card {
    min-height: calc(100vh - 150px);
    padding-bottom: 150px;
  }

  .card-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  //.card-footer {
  //  width: 100%;
  //  align-self: flex-end;
  //  border: 1px solid red;
  //  height: 200px;
  //}

  .navbar {
    display: block !important;
  }
  .main-panel {
    border-radius: 0px;
    margin: 0px;
    width: 100vw;
  }

  //Disable scrolling over to sidebar
  #root {
    overflow-x: hidden;
    background-color: white;
  }

  .navbar-transparent {
    padding-top: 15px;
    background-color: rgba(0, 0, 0, 0.45);
  }
  body {
    position: relative;
  }
  .main-panel {
    @include transform-translate-x(0px);
    @include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    left: 0;
  }
  .navbar .container {
    left: 0;
    width: 100%;
    @include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    position: relative;
  }
  .navbar .navbar-collapse.collapse,
  .navbar .navbar-collapse.collapse.in,
  .navbar .navbar-collapse.collapsing {
    display: none !important;
  }

  .navbar-nav > li {
    float: none;
    position: relative;
    display: block;
  }

  .sidebar {
    margin: 0px;
    position: fixed;
    display: block;

    //display: none;
    top: 0;
    height: 100%;
    width: $sidebar-width;
    right: 0;
    left: auto;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    padding: 0;

    @include transform-translate-x($sidebar-width);
    @include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    > ul {
      position: relative;
      z-index: 4;
      overflow-y: scroll;
      height: calc(100vh - 61px);
      width: 100%;
    }

    &::before {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: #f8f8f8;
      display: block;
      content: "";
      z-index: 1;
    }

    .sidebar-wrapper {
      height: calc(100vh);
      overflow: auto;

      .dropdown-menu {
        & > li:first-child > a,
        & > li:last-child > a {
          border-radius: 4px;
        }

        & > li > a:hover,
        & > li > a:focus {
          color: white;
        }
      }

      & > .nav > div {
        .nav {
          margin-top: 10px;
          float: none !important;

          & > li > a {
            margin: 0;
            line-height: 30px;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            margin: 5px 15px;
          }
        }

        .notification {
          float: left;
          line-height: 30px;
          margin-right: 8px;
          font-weight: 600;
        }
      }
    }

    .open .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  .nav-open {
    .sidebar {
      @include transform-translate-x(0px);
    }

    .navbar .container {
      left: -$sidebar-width;
    }

    .main-panel {
      left: 0;
      @include transform-translate-x(-$sidebar-width);
    }
  }

  .navbar-toggle .icon-bar {
    display: block;
    position: relative;
    background: #fff;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    margin: 0 auto;
  }

  .navbar-header .navbar-toggle {
    margin: 10px 15px 10px 0;
    width: 40px;
    height: 40px;
  }
  .bar1,
  .bar2,
  .bar3 {
    outline: 1px solid transparent;
  }
  .bar1 {
    top: 0px;
    @include bar-animation($topbar-back);
  }
  .bar2 {
    opacity: 1;
  }
  .bar3 {
    bottom: 0px;
    @include bar-animation($bottombar-back);
  }
  .toggled .bar1 {
    top: 6px;
    @include bar-animation($topbar-x);
  }
  .toggled .bar2 {
    opacity: 0;
  }
  .toggled .bar3 {
    bottom: 6px;
    @include bar-animation($bottombar-x);
  }

  @include topbar-x-rotation();
  @include topbar-back-rotation();
  @include bottombar-x-rotation();
  @include bottombar-back-rotation();

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .dropdown-menu .divider {
    background-color: rgba(229, 229, 229, 0.15);
  }

  .navbar-nav {
    margin: 1px 0;

    .open .dropdown-menu > li {
      & > a {
        padding: 15px 15px 5px 50px;
      }

      &:first-child > a {
        padding: 5px 15px 5px 50px;
      }

      &:last-child > a {
        padding: 15px 15px 25px 50px;
      }
    }
  }

  [class*="navbar-"] .navbar-nav {
    & > li > a,
    > li > a:hover,
    > li > a:focus,
    .active > a,
    .active > a:hover,
    .active > a:focus,
    .open .dropdown-menu > li > a,
    .open .dropdown-menu > li > a:hover,
    .open .dropdown-menu > li > a:focus,
    .navbar-nav .open .dropdown-menu > li > a:active {
      color: white;
    }

    & > li > a,
    > li > a:hover,
    > li > a:focus,
    .open .dropdown-menu > li > a,
    .open .dropdown-menu > li > a:hover,
    .open .dropdown-menu > li > a:focus {
      opacity: 0.7;
      background: transparent;
    }

    &.navbar-nav .open .dropdown-menu > li > a:active {
      opacity: 1;
    }

    & .dropdown > a {
      &:hover .caret {
        border-bottom-color: #777;
        border-top-color: #777;
      }

      &:active .caret {
        border-bottom-color: white;
        border-top-color: white;
      }
    }
  }

  .dropdown-menu {
    display: none;
  }
  .navbar-fixed-top {
    -webkit-backface-visibility: hidden;
  }
  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 250px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }

  .social-line .btn {
    margin: $margin-bottom;
  }
  .subscribe-line .form-control {
    margin: $margin-bottom;
  }
  .social-line.pull-right {
    float: none;
  }
  .footer nav.pull-left {
    float: none !important;
  }
  .footer:not(.footer-big) nav > ul li {
    float: none;
  }
  .social-area.pull-right {
    float: none !important;
  }
  .form-control + .form-control-feedback {
    margin-top: -8px;
  }
  .navbar-toggle:hover,
  .navbar-toggle:focus {
    background-color: transparent !important;
  }
  .btn.dropdown-toggle {
    margin-bottom: 0;
  }
  .media-post .author {
    width: 20%;
    float: none !important;
    display: block;
    margin: 0 auto 10px;
  }
  .media-post .media-body {
    width: 100%;
  }

  .navbar-collapse.collapse {
    height: 100% !important;
  }
  .navbar-collapse.collapse.in {
    display: block;
  }
  .navbar-header .collapse,
  .navbar-toggle {
    display: block !important;
  }
  .navbar-header {
    float: none;
  }
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .navbar-collapse {
    .nav p {
      font-size: $font-size-base;
      margin: 0;
    }

    [class^="pe-7s-"] {
      float: left;
      font-size: 20px;
      margin-right: 10px;
    }
  }

  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }

  .homepageBeginJourneyBlock {
    border-radius: 0px;
  }

  .announcementBar,
  .beginJourneyRow {
    margin: 0px;
  }

  .footerImg,
  .footerSpacer,
  .leftColumnBeginJourney,
  .homepageHeader {
    display: none;
  }

  .footerLinksContainer {
    margin: 0 auto !important;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .footerCopyright {
    margin: 20px;
    text-align: center;
  }

  .homepageStudentsRatio {
    min-height: 0px;
  }

  .infoSidebar,
  .infoAlertImg {
    display: none;
  }

  .card,
  .card-footer {
    border-radius: 0 !important;
  }
  .infoAlertContainer {
    margin: 0px;
  }

  .nextStepsDownloadPDF {
    margin: 10px 20px;
  }

  .noSidebarPanel {
    margin-top: 0px !important;
    min-width: 0px !important;
    //height: 100vh;
  }

  .noSidebarWrapper {
    display: block;
  }

  .announcementBarImg {
    display: none;
  }

  .accordion__panel:not([hidden]) {
    position: relative !important;
  }

  .noSidebarHeaderContainer {
    margin: 0px 25px;
  }

  .noSidebarContent {
    margin-top: 0px;
  }

  .text-center.campus-selector {
    padding-bottom: 120px
  }
}

@media (max-width:950px) {
  .campus-blocks a {
    display:block;
    float: unset;
    text-align: right;
    padding-top: 12px
  }
}

@media only screen and (max-width: 400px) {
    .modal-dialog {
        margin-bottom:75px
    }
}

@media only screen and (min-width: 601px) {
    .card-footer-wrapper > button + button,
    .summaryButtons > button + button,
    .confirm-delete-prompt > button + button {
        margin-left: 20px
    }
}

@media only screen and (max-width: 701px) {
    .summaryButtons > button + button {
        margin-left: unset
    }

    .summaryButtons > button {
        min-width: 75%;
        margin: 5px 0;
        padding: 0 10px;
    }
}

@media only screen and (min-width: 801px) {
    .card-footer-wrapper > button + button,
    .summaryButtons > button + button,
    .confirm-delete-prompt > button + button {
        margin-left: 50px
    }
}

//Small phones
@media only screen and (max-width: 600px) {
  .card-footer-wrapper {
    width: 75%;
  }

  .selectiveMajorP {
    font-size: 16px;
  }

  .noSidebarWrapper {
    background-color: white;
  }

  .initialInfoCard {
    width: 147px;
    height: 200px;
  }

  .initialInfoCardImg > img {
    max-width: 100px !important;
    max-height: 100px !important;
  }

  .paidColumn > img,
  .actionColumn > button {
    margin-left: auto;
  }

  .alertImage {
    display: none;
  }

  .noSidebarCard {
    align-items: unset;
  }

  .back-btn,
  .back-btn:hover,
  .back-btn:active,
  .back-btn:focus {
    min-width: 75%;
    margin: 5px 0;
    height: 40px;
    padding: 0 10px;
  }

  .tertiaryButton,
  .tertiaryButton:hover,
  .tertiaryButton:active,
  .tertiaryButton:focus,
  .card .card-footer button.tertiaryButton,
  .card .card-footer button.tertiaryButton:hover,
  .card .card-footer button.tertiaryButton:active,
  .card .card-footer button.tertiaryButton:focus {
    min-width: 75%;
    margin: 5px 0;
    height: 40px;
    padding: 0 10px;
  }
  
  .save-btn,
  .save-btn:hover,
  .save-btn:active,
  .save-btn:focus,
  .card .card-footer button.save-btn,
  .card .card-footer button.save-btn:hover,
  .card .card-footer button.save-btn:active,
  .card .card-footer button.save-btn:focus {
    min-width: 75%;
    margin: 5px 0;
    height: 40px;
    padding: 0 10px;
  }

  h1 {
    font-size: 25px !important;
    line-height: 30px !important;
  }

  .cardHeader {
    margin: 25px !important;
  }

  .buttonReviewIncomplete {
    height: 30px;
    float: none;
    width: auto !important;
    margin: 0 10px;
  }

  .cardContent {
    margin: 25px !important;
  }

  .card .table tbody td:first-child,
  .card .table thead th:first-child {
    padding-left: 8px;
  }

  .editBtn > img {
    height: 25px;
    width: 25px;
    margin: 0;
  }

  .deleteBtn > img {
    height: 25px;
    width: 25px;
  }

  .card .card-footer-wrapper {
    flex-direction: column-reverse;
  }

  .modal-dialog {
    width: auto;
  }

  .my-account-start-app-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
  }

  .modal-footer {
    text-align: center;
  }

  .modal-footer .btn + .btn {
    margin-left: unset
  }

  .cardContent .save-btn {
    margin-left: auto;
    margin-right: auto
  }

  .unpaid-warning {
    max-width: 400px
  }

  .submitted-header button,
  .submitted-header button:hover,
  .submitted-header button:active,
  .submitted-header button:focus {
    font-size: 13px;
    bottom: unset;
    top: 0
  }

  .unpaid-warning {
    max-width: 200px
  }
}

@media (min-height: 900px) {
    .yt-container-congrats iframe[title="YouTube video player"] {
        width: 500px;
        max-width:90%;
        height: calc(500px * (705 / 1253));
    }
}

@media (min-height: 700px) and (max-height: 899px) {
    .yt-container-congrats iframe[title="YouTube video player"] {
        width: 400px;
        max-width:90%;
        height: calc(400px * (705 / 1253));
    }
}

@media (min-height: 500px) and (max-height: 699px) {
    .yt-container-congrats iframe[title="YouTube video player"] {
        width: 300px;
        max-width:90%;
        height: calc(300px * (705 / 1253));
    }
}

@media (max-width: 1440px) {
  [data-layout='home'] div.content {
    border-radius: 0;
    border-top-right-radius: 0;
  }
}

@media (max-width: 1326px) {
  .sidebarHeader,
  .sidebar .sidebar-wrapper {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .main-panel:nth-child(2) .card {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .card .card-footer {
    border-bottom-right-radius: 0
  }

  .noSidebarWrapper .card .card-footer {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px
  }

  .noSidebarWrapper .card.noSidebarCard .card-footer {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px
  }
  
  .progress-bar {
    width: calc(100% - 10px);
    margin-left: 10px
  }

  .progressText {
    padding-left: 10px
  }
}

