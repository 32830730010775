//Utilities

@import "mixins/transparency";
@import "mixins/vendor-prefixes";

//Components

@import "mixins/buttons";
@import "mixins/inputs";
@import "mixins/labels";
@import "mixins/tabs";

@import "mixins/navbars";
@import "mixins/icons";
@import "mixins/social-buttons";

@import "mixins/morphing-buttons";

@import "mixins/cards";

@import "mixins/chartist";

@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
  border: none !important;
  border-radius: 2px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid black !important;
}

.accordion__button {
  background-color: white !important;

  cursor: pointer;
  padding: 30px !important;
  width: 100%;
  text-align: left;

  font-family: "Open Sans", sans-serif;
  color: #444;
  font-size: 18px;
  line-height: 145%;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.accordion__button:hover {
  background-color: #ddd;
}

.accordion__button:before {
  display: inline-block;
  content: none !important;
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 5px solid currentColor;
  border-right: 5px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
  transform: rotate(45deg);
}

.accordionPlus {
  margin-left: auto;
  margin-top: 10px;
}

.accordion__panel {
  padding: 10px !important;
  animation: fadein 0.35s ease-in;

  & > p {
    margin-left: 20px;
    padding: 0 30px !important;
    width: 100%;
    text-align: left;
    font-family: "Open Sans", sans-serif;
    color: #444;
    font-size: 18px;
    line-height: 145%;
  }
  & ul {
    padding: 0 30px 0 90px;
  }
  & li {
    font-size: 18px;
    line-height: 145%;
  }
}

.react-tel-input {
  height: 50px;
  border-radius: 10px;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 50px !important;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.css-1fxn8iv {
  margin: 0px !important;
  margin-bottom: 20px !important;
}

.css-1eaafrb {
  color: #444444 !important;
}

//Password strength component styles

.ReactPasswordStrength {
  //border: 1px solid #c6c6c6;
  box-sizing: border-box;
  color: #090809;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1;
  position: relative;
}

.ReactPasswordStrength-input {
  border: none;
  box-sizing: border-box;
  font-size: 18px;
  padding: 14px 0 12px 14px;
  width: 100% !important;
}

.ReactPasswordStrength-input:not(:focus).is-password-invalid {
  color: #d1462f;
}

.ReactPasswordStrength-input:focus.is-password-invalid {
  color: #d1462f;
}

.informationTooltip {
  height: 20px;
  width: 20px;
  margin-top: -7px;
  margin-left: 5px;
}

.card .card-footer a.info-submit-wrapper {
  background-color: unset;
  border: none;
  box-shadow: unset;
  min-width: unset;
  padding: 14px 0;
  margin-left: 12px;
  display: block;
  z-index: 99
}

.card .card-footer a.info-submit-wrapper-hidden {
  visibility: hidden;
}

.card .card-footer a.info-submit-wrapper img.informationTooltip {
  margin: 0
}

.blocker-tooltip-item {
  display: block;
  font-size: 14px;
  line-height: 24px
}

.blocker-tooltip-item::before {
  content: '\24E7';
  font-size: 16px;
  line-height: 24px;
  margin-right: 6px
}

//.ReactPasswordStrength-input:focus { outline: none; }

.ReactPasswordStrength-strength-desc {
  color: transparent;
  font-style: italic;
  padding: 14px 12px;
  position: absolute;
  top: 1px;
  right: 0;
  text-align: right;
  transition: color 250ms ease-in-out;
  width: 15%;
}

.ReactPasswordStrength.is-strength-0 .ReactPasswordStrength-strength-desc {
  color: #ff3d36;
}

.ReactPasswordStrength.is-strength-1 .ReactPasswordStrength-strength-desc {
  color: #fff341;
}

.ReactPasswordStrength.is-strength-2 .ReactPasswordStrength-strength-desc {
  color: #2fbf71;
}

.ReactPasswordStrength.is-strength-3 .ReactPasswordStrength-strength-desc {
  color: #2fbf71;
}

.ReactPasswordStrength.is-strength-4 .ReactPasswordStrength-strength-desc {
  color: #2fbf71;
}

.ReactPasswordStrength-strength-bar {
  margin: -1px;
  box-sizing: border-box;
  height: 2px;
  position: relative;
  top: 1px;
  right: 1px;
  transition: width 300ms ease-out;
  width: 0;
}

.ReactPasswordStrength.is-strength-0 .ReactPasswordStrength-strength-bar {
  background: #ff3d36;
  width: 30%;
}

.ReactPasswordStrength.is-strength-1 .ReactPasswordStrength-strength-bar {
  background: #ff3d36 !important;
  width: 50% !important;
}

.ReactPasswordStrength.is-strength-2 .ReactPasswordStrength-strength-bar {
  background: #fff341 !important;
  width: 80% !important;
}

.ReactPasswordStrength.is-strength-3 .ReactPasswordStrength-strength-bar {
  background: #2fbf71 !important;
  width: calc(100% + 2px) !important;
}

.ReactPasswordStrength.is-strength-4 .ReactPasswordStrength-strength-bar {
  background: #2fbf71 !important;
  width: calc(100% + 2px) !important;
}

//Notification colors
.Toastify__toast--info {
  background-color: $uwRed !important;
}

.Toastify__toast--warning {
  background-color: $dark-gray !important;
}

.Toastify__toast-container {
  width: 400px !important;
}

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */
.rdt {
  position: relative;
}

.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9;
}

.rdtOpen .rdtPicker {
  display: block;
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}

.rdtPicker td {
  cursor: pointer;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}

.rdtPicker td.rdtToday {
  position: relative;
}

.rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}

.rdtPicker th.rdtSwitch {
  width: 100px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 30px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker thead tr:first-child th {
  cursor: pointer;
}

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}

.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.rdtCounter .rdtBtn:hover {
  background: #eee;
}

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

#tooltip {
  font-size: 16px;
  color: $uwBlue;
  font-style: italic;
  font-weight: 500;
}

.tooltipContainer {
  max-width: 600px;
  word-wrap: break-word;
  text-transform: none;
  z-index: 999;
}

.extraClass {
  font-size: 16px !important;
  pointer-events: auto !important;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}
