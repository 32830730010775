input[type="radio"],
input[type="checkbox"] {
  margin-top: 5px;
}

.radio {
  display: inline-block;
  margin: 10px 10px 0 10px;
  margin-bottom: 0px;
}

.radio label {
  font-family: "Open Sans", sans-serif !important;
  font-size: 16px;
}

.majorRadios {
  display: block !important;
  float: left !important;
  width: 100% !important;
}

.radio input:after {
  width: 18px;
  height: 18px;
  border-radius: 20px;
  top: -2px;
  left: -2px;
  position: relative;
  background-color: white;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1px solid $dark-gray;
}

.radio input:checked:after {
  width: 18px;
  height: 18px;
  border-radius: 20px;
  top: -2px;
  left: -2px;
  position: relative;
  background-color: $dark-gray;
  content: "";
  display: inline-block;
  visibility: visible;
  //border: 1px solid $dark-gray;
}

//input[type='radio']:focus {
//    &::before {
//        width: 18px;
//        height: 18px;
//        border-radius: 20px;
//        top: -2px;
//        left: -4px;
//        position: relative;
//        background-color: $dark-gray;
//        content: '';
//        display: inline-block;
//        visibility: visible;
//    }
//
//    &::after {
//        width: 11px;
//        height: 1px;
//        top: -14px;
//        left: -2px;
//        background-color: $dark-gray;
//        color: $dark-gray;
//        position: relative;
//        display: inline-block;
//        visibility: visible;
//    }
//}

.customRadioContainer {
  border-radius: 10px;
  height: 50px;
  background-color: white;
  padding-left: 20px;

  display: flex;
  justify-content: left;
  align-items: center;

  margin: 0px;
}

.checkbox {
  display: inline-block;
  padding: 10px;
}

.checkbox input:after {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  top: -2px;
  left: -2px;
  position: relative;
  background-color: white;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1.25px solid $dark-gray;
}

.checkbox input:checked:after {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  top: -2px;
  left: -2px;
  position: relative;
  background-color: $dark-gray;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1.25px solid $dark-gray;
}

//input[type='checkbox']:hover, input[type='checkbox']:focus {
//
//    &::after {
//        width: 18px;
//        height: 18px;
//        border-radius: 3px;
//        top: -1px;
//        left: -5px;
//        position: relative;
//        content: '';
//        display: inline-block;
//        visibility: visible;
//        border: 1.25px solid $dark-gray;
//        box-shadow: 0 0px 8px $uwBlue;
//    }
//}

.campusRadio {
  border: 1px solid #878787;
  background: #ffffff;
  border-radius: 10px;

  height: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.campusRadioInput {
  margin-top: 0px;
}

.chkbox-container {
    margin-bottom:18px
}

.chkbox-container input {
    vertical-align: top;
    margin-top: 6px;
}

.chkbox-container label {
    width: calc(100% - 16px);
    padding-left: 12px;
    display:inline-block
}
