.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
}

.hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  opacity: 1;
}

.hero-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  max-width: 1440px;
  margin-bottom: 0px;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #fff;
  -webkit-perspective: 2000px;
  perspective: 2000px;
  text-align: center;
}

.h1 {
  margin-top: 0px;
  margin-bottom: 30px;
  color: #eee;
  font-size: 72px;
  line-height: 78px;
  font-weight: 800;
  letter-spacing: 1px;
}

.paragraph {
  color: #fff;
  font-size: 21px;
  line-height: 24px;
  font-weight: 400;
}

.navigation {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100px;
  padding-right: 30px;
  padding-left: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.navigation-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 90px;
  max-width: 1440px;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo {
  margin-top: 20px;
  margin-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-link {
  width: auto;
  font-family: "Open Sans", sans-serif;
  color: #eee;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0px;
  cursor: pointer;
}

.nav-link.w--current {
  color: grey;
}

.nav-link.button {
  margin-top: 0px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 0px;
  background-color: transparent;
  letter-spacing: 0px;
}

.nav-link.hide {
  display: none;
}

.menu-button {
  display: none;
}

.image {
  width: 200px;
}

.image-2 {
  max-height: 400px;
  max-width: none;
}

.right-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 400px;
  max-height: 500px;
  max-width: none;
  margin-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: transparent;
  color: #444;
}

.hp-heading-blue {
  padding-right: 10px;
  font-family: brandon-grotesque, sans-serif;
  color: #005777;
  font-size: 54px;
  line-height: 76px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
}

.body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: brandon-grotesque, sans-serif;
}

.hp-heading-red {
  font-family: brandon-grotesque, sans-serif;
  color: #D44427;
  font-size: 54px;
  line-height: 76px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
}

.byj-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.left-hero {
  display: block;
  width: 40%;
  max-height: 500px;
  padding-right: 5%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.hp-subheading {
  margin-bottom: 20px;
  color: #444;
  font-size: 36px;
  font-weight: 500;
}

.hero-button-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}

.hero-button-wrap a {
  display: inline-table;
  width: 250px;
  height: 75px;
  margin-right: 10px;
  margin-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.hero-button-wrap input[type='button'].btn-general {
  width: 250px;
  height: 75px;
  display: inline-block;
  white-space: normal;
}

.hero-button-wrap a {
  background-color: #005777;
}

.hero-button-wrap a:hover {
  background-color: #00aeef;
}

.hero-button-wrap a:first-child {
  background-color: #D44427;
}

.hero-button-wrap a:first-child:hover {
  background-color: #00aeef;
}

.hero-button-wrap a:last-child {
  margin: 12px auto;
  width: 300px
}

.choose-login-subtitle {
  margin-top: 20px;
  margin-bottom: 15%;
  color: #005777;
  font-size: 21px;
  line-height: 150%;
  font-weight: 500;
  text-align: center;
}

.link {
  /*font-family: 'Open Sans', sans-serif;*/
  font-family: brandon-grotesque, sans-serif;
  color: #D44427;
  font-size: 21px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.link:hover {
  color: #00aeef;
}

.announcement-bar {
  display: block;
  width: 100%;
  height: 100px;
  max-width: 1440px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  background-color: #D44427;
}
.announcement-bar.practice {
  background-color: #f4ea3e;
}

.announcement-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.announcement-icon {
  height: 75px;
  margin-right: 20px;
}

.announcement {
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}
.announcement a {
    font-weight: bold;
    text-decoration: underline;
}
.announcement a,
.announcement a:hover,
.announcement a:focus,
.announcement a:active {
    color: #fff;
    text-decoration: underline;
}
.announcement-bar.practice .announcement a,
.announcement-bar.practice .announcement a:hover,
.announcement-bar.practice .announcement a:focus,
.announcement-bar.practice .announcement a:active {
    color: #000;
}
.announcement-bar.practice .announcement {
    color: #000
}

.faqs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.paragraph-top {
  max-width: 650px;
  margin-right: auto;
  margin-left: auto;
  font-family: 'Open Sans', sans-serif;
  color: #637178;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.27px;
}

.heading {
  margin-top: 0px;
  margin-bottom: 15px;
  color: #005777;
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.faq-question-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 25px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #444;
  border-radius: 0px;
  background-color: transparent;
  box-shadow: 7px 7px 60px 0 #f2f3f6;
  text-decoration: none;
}

.link-3 {
  color: #784fe0;
  text-decoration: none;
}

.faq-content {
  overflow: hidden;
  margin-top: -2px;
}

.faqs-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 800px;
  margin-right: auto;
  margin-bottom: 70px;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.question-title {
  font-family: 'Open Sans', sans-serif;
  color: #444;
  font-size: 18px;
  line-height: 145%;
}

.faq-question-wrap {
  margin-bottom: 35px;
}

.question-icon {
  margin-bottom: 35px;
  -webkit-filter: saturate(0%);
  filter: saturate(0%);
}

.animation {
  width: 60px;
  height: 60px;
}

.faqs-paragraph {
  margin-right: 25px;
  margin-bottom: 0px;
  margin-left: 25px;
  padding-top: 42px;
  padding-bottom: 42px;
  font-family: 'Open Sans', sans-serif;
  color: #4e4e4e;
  font-size: 18px;
  line-height: 145%;
  letter-spacing: -0.25px;
}

.link-4 {
  color: #191919;
  text-decoration: none;
}

.link-4:hover {
  color: #48c3ef;
}

.faq-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.faq-container {
  position: relative;
  width: 100%;
  min-height: auto;
  padding: 60px 5% 120px;
  background-color: #fff;
}

.text-block {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
}

.navigation-2 {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 30px;
  padding-left: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.navigation-container-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 90px;
  max-width: 1170px;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-menu-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-link-2 {
  font-family: brandon-grotesque, sans-serif;
  color: #eee;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 2px;
}

.nav-link-2.w--current {
  color: grey;
}

.nav-link-2.button {
  margin-top: 0px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 0px;
  background-color: transparent;
  letter-spacing: 0px;
}

.image-3 {
  width: 200px;
}

.div-block {
  width: 100%;
}

.section {
  width: 100%;
}

p.intro-copy {
    margin-bottom: 24px
}

p.intro-copy.extra {
  margin-top: 48px
}

p.intro-copy img.informationTooltip {
    margin-left: 8px
}

p.verify-email-change {
    margin-top: 48px
}

p.link-da-no-code {
  margin: 60px 0 0 0
}

section.chk-online-wrapper {
    width: 50%;
    display: inline-block;
    padding: 36px 0 0 18px;
}

a.campus-search-filter {
    padding: 12px 0 12px 6px;
    position: relative;
    display: block;
    font-weight: bold;
    text-transform: uppercase;
}

a.campus-search-filter img {
    display: inline-block;
    width: 20px;
    margin-right: 8px
}

div.success-img-wrapper {
    border: 16px solid #118742;
    width: 140px;
    height: 140px;
    margin: 48px auto;
    border-radius: 50%
}

div.success-img-wrapper img {
    margin: 0 0 0 11px;
    max-width: 120px
}

@media (max-width: 991px) {
    section.chk-online-wrapper {
        padding-top: 12px
    }

    a.campus-search-filter {
        margin-top: 12px
    }
}

section.campus-opt-group {
    background-color: #eee;
    display: inline-block;
    padding: 12px 24px;
    margin: 4px 6px 4px 0;
    width: calc(50% - 6px);
    vertical-align: top;
    border-radius: 12px
}

section.campus-opt-group div {
    padding-bottom:4px
}

section.campus-opt-group div label {
    color: #D44427;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 95%
}

section.campus-opt-group p {
    margin: 0 0 0 18px;
    padding: 0;
}

section.campus-opt-group p label {
    font-size: 90%
}

div.find-major-card, div.select-major-card {
    padding-bottom: 48px
}

div.acad-level-picker {
    position: absolute;
    top: 41px;
    right: 60px;
    border: 1px solid #BDBDBD;
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    font-family: 'Open Sans';
    font-size: 12px;
    line-height: 14px;
}

div.acad-level-picker button {
    border: none;
    color: #BDBDBD;
    width: 131px;
    text-align: center;
    padding: 8px 0;
    font-weight: 600
}

@media screen and (max-width: 991px) and (min-width: 651px) {
    div.acad-level-picker {
        top: 102px
    }
}

@media screen and (max-width: 650px) {
    div.acad-level-picker {
        position: relative;
        top: unset;
        right: unset;
        width: 100%;
        margin-bottom: 24px
    }

    div.acad-level-picker button {
        width: 50%
    }
}

div.acad-level-picker button.selected {
    background: linear-gradient(180deg, #828282 0%, #333333 100%);
    color: #fff
}

div.acad-level-picker button:first-child {
    border-radius: 3px 0 0 3px
}

div.acad-level-picker button:nth-child(2) {
    border-radius: 0 3px 3px 0
}

p.selected-campus {
    position: absolute;
    top: 41px;
    right: 60px;
    font-family: brandon-grotesque, sans-serif;
    color: #D44427;
    font-size: 18px;
    font-weight: 450
}

@media screen and (max-width: 825px) {
    p.selected-campus {
        display: none
    }
}

div.major-search-controls {
    width: 100%;
    padding-bottom: 24px;
    position: relative
}

div.select-major-card div.major-search-controls {
    text-align: right
}

div.select-major-card div.major-search-controls div {
    text-align: left;
}

div.select-major-card div.major-search-controls > div:not(:first-child) {
    width: 50%
}

div.major-search-controls input:first-child {
    border-top: none;
    border-right: none;
    border-left: none;
    width: 100%;
    font-size: 26px;
    line-height: 32px;
    font-weight: 300;
    margin-bottom: 26px;
    color: #222;
    padding: 10px 0 10px 8px
}

div.major-search-controls input:first-child:hover {
    cursor: default;
}

div.major-search-controls button.major-clear {
    font-size: 50px;
    line-height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    color: #999;
    padding: 3px 14px
}

div.major-search-controls > * {
    vertical-align: top
}

@media screen and (max-width: 700px) {
    div.major-search-controls > * {
        width: 100%;
        margin-bottom: 6px;
    }

    div.major-search-controls div div {
        margin-left: 0;
        margin-right: 0;
    }

    div.select-major-card div.major-search-controls > div:not(:first-child) {
        width: 100%
    }
}

p.select-undecided {
    text-align: right;
}

div.short-list-line {
    padding-top: 24px
}

div.short-list-line h2 {
    display: inline;
    font-family: 'Open Sans';
    color: #BDBDBD;
    font-size: 26px;
    line-height: 26px;
    font-weight: 300;
}

div.short-list-line p.select-undecided {
    display: inline;
    float: right
}

button.link-button-red {
    color: #D44427;
    line-height: unset;
    vertical-align: inherit;
    font-size: inherit;
    line-height: inherit;
    padding: 0;
    margin: 0;
    border: none
}

p.major-results-counter {
    padding-bottom: 19px
}

div.select-major-results-line p {
    display: inline
}

div.select-major-results-line p.select-undecided {
    float: right
}

section.find-prog {
    background-color: #F7F6F6;
    border-radius: 12px;
    padding-bottom: 20px;
    margin-top: 10px;
    display: inline-block;
    width: 100%
}

section.find-prog h3 {
    font-family: 'Open Sans';
    text-transform: uppercase;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.05em;
    font-weight: 700;
    height: 60px;
    background-color: #F2F2F2;
    padding: 20px 20px 16px;
    border-radius: 12px 12px 0 0;
}

section.find-prog h3 span {
    float: right;
    font-weight: 300
}

section.find-prog h3:empty {
    height: 0
}

section.find-prog a {
    color: #D44427;
    font-weight: 600;
}

section.find-prog a:hover {
    cursor: pointer;
    color: #D44427;
}

section.find-prog a.disabled,
section.find-prog a.disabled span {
    color: #bfbfbf;
    font-style: italic;
}

section.find-prog a.disabled:hover, section.find-prog a.no-click:hover {
  cursor: unset
}

section.find-prog a.parent-line {
    display: block;
    width: calc(100% - 40px);
    padding: 5px 10px;
    margin: 11px 0 5px 30px;
    font-size: 21px;
    line-height: 26px;
}

section.find-prog a.parent-line > span:first-child {
    display: inline-block;
    width: calc(100% - 125px)
}

section.find-prog span.prog-type {
    font-weight: normal;
    color: #707070;
    font-size: 9px;
    line-height: 14px;
    margin-left: 7px;
    text-transform: uppercase;
}

section.find-prog span.dist-learning {
    float: right;
    font-size: 14px;
    line-height: 24px;
    color: #707070;
    font-weight: bold;
}

section.find-prog a.parent-line span.dist-learning {
    padding-top: 4px
}

section.find-prog a.child-line {
    display:block;
    width: calc(100% - 90px);
    border-bottom: 1px solid #E0E0E0;
    margin: 0 20px 0 70px;
    padding: 7px 0;
}

section.find-prog a.child-line > span.cut-off {
    display: inline-block;
    width: calc(100% - 125px)
}

section.find-prog p.keywords {
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 9px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #4F4F4F;
    text-align: center;
    text-transform: uppercase;
    padding: 20px 20px 0
}

section.find-prog a.selected {
    background-color: #D44427;
    color: #fff;
    border-radius: 6px
}

section.find-prog a.child-line.selected {
    background-color: #D44427;
    width: calc(100% - 70px);
    margin: 0 12px 0 58px;
    padding: 7px 8px 7px 12px;
    border-radius: 6px
}

section.find-prog a.selected,
section.find-prog a.child-line.selected,
section.find-prog a.selected span,
section.find-prog a.child-line.selected span {
    color: #fff;
}

section.find-prog a.parent-line span.str-match {
    border-bottom: 7px solid rgba(198, 45, 73, 0.1);
    line-height: 19px;
    display: inline-block
}

section.find-prog a.child-line span.str-match {
    border-bottom: 7px solid rgba(198, 45, 73, 0.1);
    line-height: 13px;
    display: inline-block
}

section.find-prog p.keywords span.str-match {
    border-bottom: 7px solid rgba(198, 45, 73, 0.1);
    line-height: 7px;
    display: inline-block
}

a.prog-tooltip img {
  height: 16px;
  width: 16px;
  vertical-align: top;
  margin: 0 0 0 6px;
}

section input[type=checkbox] + label {
    display:inline;
    padding-left:12px
}

.no-progs {
    border-top:1px solid #eee;
    padding: 24px 0;
    text-align: center;
    font-weight: bold
}

.hp-photo {
  width: 25%;
  height: 300px;
  max-width: 1440px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.image-bar {
  width: 100%;
}

.footer {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 40px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  background-color: #eee;
  text-align: center;
}

.footer-flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: left;
}

.footer-logo-link {
  max-height: 60px;
  min-width: 60px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.footer-image {
  width: 200px;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 0% 50%;
  object-position: 0% 50%;
}

.footer-heading {
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.5;
}

.footer-link {
  display: block;
  margin-bottom: 10px;
  color: #444;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.text-block-2 {
  font-family: 'Open Sans', sans-serif;
  color: #444;
}

.bold-text {
  font-family: brandon-grotesque, sans-serif;
}

.body-2 {
  margin-top: 0%;
  padding-top: 5%;
  padding-right: 5%;
  padding-left: 5%;
}

.two-column-view {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 100vh;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: transparent;
  opacity: 1;
}

.left-column {
  position: -webkit-sticky;
  position: sticky;
  top: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  max-height: none;
  margin-right: 5%;
  padding-right: 0%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.right-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 800px;
  height: auto;
  max-height: none;
  max-width: 1000px;
  margin-left: 0px;
  padding: 0%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: transparent;
  color: #444;
}

.div-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100px;
  padding-right: 40px;
  padding-left: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #D44427;
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  padding: 10% 5%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.image-4 {
  width: auto;
  height: 100px;
  margin-top: 10%;
  margin-bottom: 5%;
}

.menu-link {
  margin-bottom: 20%;
  font-family: brandon-grotesque, sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.homepage {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100px;
  margin-top: 0%;
  padding: 0% 5%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px 0px 10px 10px;
  background-color: #eee;
}

.form-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

div.direct-admit-form div.form-block {
  align-items: unset
}

div.direct-admit-form h1 {
  text-align: center;
  margin-bottom: 24px
}

div.direct-admit-form h2 {
  margin-top: 36px
}

div.direct-admit-form input[type="button"].btn-general {
  align-self: start
}

input[type="button"].btn-general.btn-no-create {
  margin-bottom: 48px
}

.text-field {
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.field-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
}

p + label.field-label {
    margin-top: 24px
}

.field-label-2 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.submit-button {
  width: auto;
  height: 48px;
  min-width: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-radius: 10px;
  background-color: #D44427;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.submit-button:hover {
  background-color: #00aeef;
}

.form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.column-heading {
  margin-top: 0px;
  margin-bottom: 15px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-family: brandon-grotesque, sans-serif;
  color: #005777;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.column-heading.white {
  margin-bottom: 0px;
  color: #fff;
}

.back-to-home {
  margin-bottom: 0%;
  font-family: brandon-grotesque, sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.div-block-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 5%;
  padding: 5%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.announcement-model-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100px;
  padding-right: 5%;
  padding-left: 5%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #D44427;
}

.right-column-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 5%;
  padding: 5%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
}

.save-error {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 2% 0 2% 0;
  padding: 2%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #f8f8f8;
}
.save-error::before {
  content: ' ';
  margin-right: 2%;
  background-image: url('../img/exclamation-triangle-solid.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 81px;
  height: 53px;
}

.announcement-modal {
  width: 100%;
  margin-bottom: 5%;
  padding: 12px 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #D44427;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  position: relative;
  min-height: 99px;
  position: relative;
  display: flex;
  border: 3px solid #fff
}
.announcement-modal > img {
    position: absolute;
    top: calc(50% - 37px);
    left: 24px;
}
.announcement-modal div {
    display: flex;
    justify-content:flex-start;
    align-items: center;
    margin-left: 99px;
    width: calc(100% - 135px)
}
.announcement-modal div a {
    text-decoration: underline;
    font-weight: bold;
}
.announcement-modal div a,
.announcement-modal div a:hover,
.announcement-modal div a:focus,
.announcement-modal div a:active {
    color: #fff;
}
.warning-modal .modal-header{
  background-color: #D44427;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.warning-modal h4 {
  color: #ffffff;
  margin: 20px 0 10px 0;
}
.warning-modal .close {
  color: #EEEEEE;
}

.modal-footer input[type=checkbox] {
    margin-right:12px;
    display:inline
}

.modal-footer input[type=checkbox] + label {
    display:inline;
}

.paragraph-6 {
  padding: 5%;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 145%;
}

.button-2 {
  height: 50px;
}

.button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 48px;
  min-width: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-radius: 10px;
  background-color: #005777;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.button:hover {
  background-color: #00aeef;
}

.nav-2-columns {
  position: relative;
  z-index: 1;
  display: none;
  height: 100px;
  padding-right: 30px;
  padding-left: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.footer-nav {
  width: auto;
  font-family: brandon-grotesque, sans-serif;
  color: #444;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0px;
  cursor: pointer;
}

.footer-nav.w--current {
  color: grey;
}

.footer-nav.button {
  margin-top: 0px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 0px;
  background-color: transparent;
  letter-spacing: 0px;
}

.image-5 {
  height: 50%;
  margin-right: 20px;
}

.help-info {
  margin-bottom: 20px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-family: 'Open Sans', sans-serif;
  color: #444;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  text-decoration: none;
}

.help-info:hover {
  color: #00aeef;
}

.help-info-copy {
  margin-bottom: 20px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  font-family: 'Open Sans', sans-serif;
  color: #444;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}

.help-info-copy:hover {
  color: #00aeef;
}

.text-block-3 {
  width: 100%;
  margin-bottom: 12px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 145%;
}

.permission-to-text {
    margin-bottom: 36px
}

.text {
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 145%;
}

.radio-button-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

.radio-button-label-2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

.radio-button-field {
  margin-bottom: 20px;
}

.italic-text {
  font-style: normal;
}

.radio-button-field-2 {
  margin-bottom: 40px;
}

.choose-login {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  max-width: 1440px;
  padding: 0%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  background-color: #fff;
}

.choose-login-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 500px;
  min-height: 250px;
  margin-right: 2%;
  margin-left: 2%;
  padding: 2%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none;
  border-width: 5px;
  border-color: #D44427;
  border-radius: 10px;
  background-color: #eee;
}

.old-login {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 250px;
  height: 75px;
  margin-right: 10px;
  margin-bottom: 5%;
  margin-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #005777;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.old-login:hover {
  background-color: #00aeef;
  color: white;
}

.w-button:hover {
  background-color: #00aeef;
  color: white;
}

.footer-nav:hover {
  color: #70D0F6;
}

.choose-login-hero-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30%;
  height: 100%;
  max-height: 500px;
  max-width: none;
  margin-left: 0px;
  padding: 3%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px 10px 0px 0px;
  background-color: #eee;
  color: #444;
}

.choose-login-hero-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70%;
  height: 100%;
  max-height: 500px;
  margin-right: 0%;
  padding: 3% 5%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  text-align: center;
}

.choose-login-link {
  font-family: 'Open Sans', sans-serif;
  color: #D44427;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}

.choose-login-link:hover {
  color: #00aeef;
}

.choose-login-subheading {
  margin-bottom: 20px;
  color: #fff;
  font-size: 36px;
  line-height: 120%;
  font-weight: 500;
  text-align: center;
}

.blue-heading {
  margin-top: 0px;
  padding-right: 0px;
  font-family: brandon-grotesque, sans-serif;
  color: #fff;
  font-size: 54px;
  line-height: 120%;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
}

.new-portal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 250px;
  height: 75px;
  margin-right: 10px;
  margin-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #005777;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.new-portal:hover {
  background-color: #00aeef;
}

.bold-text-2 {
  text-decoration: underline;
}

.bold-text-3 {
  text-decoration: underline;
}

.bold-text-4 {
  text-decoration: underline;
}

.choose-login-div-blue {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: none;
  min-height: auto;
  margin-right: 0%;
  margin-left: 0%;
  padding: 5%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
  border-radius: 0px;
  background-color: #D44427;
}

.landing-page-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  max-width: 1440px;
  margin-bottom: 0px;
  padding: 2% 5%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #fff;
  -webkit-perspective: 2000px;
  perspective: 2000px;
  text-align: center;
}

.image-2-copy {
  max-height: 200px;
  max-width: none;
}

.blue-heading-copy {
  width: auto;
  margin-top: 0px;
  margin-left: 0%;
  padding-right: 0px;
  font-family: brandon-grotesque, sans-serif;
  color: #005777;
  font-size: 54px;
  line-height: 120%;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  text-transform: none;
}

.image-2-copy-copy {
  max-height: 75px;
  max-width: none;
  margin-right: 20px;
}

.choose-login-subtitle-copy {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5%;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-size: 21px;
  line-height: 150%;
  font-weight: 500;
  text-align: center;
  text-transform: none;
}

.text-block-4 {
  font-family: 'Open Sans', sans-serif;
  color: #D44427;
  font-style: italic;
  font-weight: 400;
  text-align: center;
}

.text-block-5 {
  padding-top: 10px;
  padding-bottom: 10px;
  border-style: solid;
  border-width: 3px;
  border-color: #D44427;
  border-radius: 20px;
  background-color: transparent;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}

.image-2-copy-copy {
  height: auto;
  max-height: 400px;
}

.div-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.choose-login-hero-left-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  max-height: 500px;
  margin-right: 5%;
  padding-right: 0%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  text-align: center;
}

.image-2-copy-copy {
  max-height: 100px;
  max-width: none;
}

.blue-heading-copy-copy {
  width: 100%;
  margin-top: 0px;
  margin-left: 0%;
  padding-right: 0px;
  font-family: brandon-grotesque, sans-serif;
  color: #005777;
  font-size: 54px;
  line-height: 120%;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  text-transform: none;
}

.text-block-5-copy {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-style: solid;
  border-width: 3px;
  border-color: #D44427;
  border-radius: 20px;
  background-color: transparent;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.image-2-copy-copy-copy {
  max-height: 100px;
  max-width: none;
  margin-right: 0px;
}

.landing-page-hero-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 500px;
  max-width: 1440px;
  margin-bottom: 0px;
  padding: 0%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #fff;
  -webkit-perspective: 2000px;
  perspective: 2000px;
  text-align: center;
}

.image-2-copy-copy-copy {
  height: auto;
  max-height: 250px;
  margin-right: 5%;
}

.bold-text-5 {
  color: #D44427;
}

.image-2-copy-copy {
  max-height: 50px;
  max-width: none;
}

.term-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 350px;
  height: 300px;
  padding: 0%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-style: none;
  border-width: 4px;
  border-color: #005777;
  border-radius: 10px;
  background-color: #fff;
}

.terms {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 40px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.term-heading {
  color: #fff;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  text-transform: none;
}

.term-paragraph {
  margin-right: 5%;
  margin-bottom: 10%;
  margin-left: 5%;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 14px;
  line-height: 150%;
  text-align: left;
}

.term-date {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 125px;
  margin-bottom: 15%;
  padding: 2% 5%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #005777;
}

.terms-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75%;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 40px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-8-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.blue-heading-copy-copy {
  width: auto;
  margin-top: 0px;
  margin-left: 0%;
  padding-right: 0px;
  font-family: brandon-grotesque, sans-serif;
  color: #005777;
  font-size: 54px;
  line-height: 120%;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  text-transform: none;
}

.choose-login-hero-right-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30%;
  height: 100%;
  max-height: 500px;
  max-width: none;
  margin-left: 0px;
  padding: 3%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px 10px 0px 0px;
  background-color: #eee;
  color: #444;
}

[data-page='reciprocity'] p a:first-child:last-child {
  font-weight: 700;
}

[data-page='reciprocity'] p a:first-child:last-child:hover {
  cursor: pointer;
}

.recip-faq section {
  display: inline-block;
  width: calc(50% - 12px);
  vertical-align: top;
}

.recip-faq h3 + section {
  padding-right: 24px;
}

a.close-link-top {
  float: right;
  font-family: brandon-grotesque, sans-serif;
  font-weight: 700;
}

a.close-link-top:hover {
  cursor: pointer
}

.recip-faq section h4 {
  font-weight: 700;
  font-size: 12px;
  margin: 24px 30px 10px;
  padding: 6px 0;
  text-align: center;
  text-transform: uppercase;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000
}

.recip-faq section li {
  font-size: 14px;
  padding-top: 1px;
}

@media screen and (min-width: 1280px) {
  .link {
    font-size: 18px;
  }
  .footer {
    background-color: #eee;
  }
  .text-block-2 {
    color: #444;
  }
  .announcement-model-header {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .paragraph-6 {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: 145%;
    font-weight: 400;
  }
  .footer-nav {
    color: #444;
  }
  .image-5 {
    height: 50%;
    margin-right: 20px;
  }
  .help-info {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 400;
  }
  .help-info-copy {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
  }
  .choose-login-link {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
  }
}

@media screen and (min-width: 1440px) {
  .choose-login-subtitle {
    margin-bottom: 5%;
    text-align: left;
  }
  .link {
    font-size: 18px;
    font-weight: 700;
  }
  .footer {
    background-color: #eee;
  }
  .text-block-2 {
    color: #444;
  }
  .paragraph-6 {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: 145%;
  }
  .footer-nav {
    color: #444;
  }
  .help-info {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 700;
  }
  .help-info-copy {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 700;
  }
  .choose-login-div {
    padding-right: 2%;
    padding-bottom: 2%;
    padding-left: 2%;
    background-color: #eee;
  }
  .old-login {
    margin-bottom: 5%;
  }
  .choose-login-hero-right {
    padding: 2% 3%;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    background-color: #eee;
  }
  .choose-login-hero-left {
    margin-right: 0%;
    padding: 2% 5%;
  }
  .choose-login-link {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 700;
  }
  .blue-heading {
    margin-top: 0px;
    padding-right: 0px;
  }
  .choose-login-div-blue {
    padding: 2%;
    border-radius: 0px;
  }
  .landing-page-hero {
    padding-top: 2%;
    padding-bottom: 2%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .image-2-copy {
    max-height: 200px;
    margin-right: 5%;
  }
  .blue-heading-copy {
    margin-top: 0px;
    margin-left: 0%;
    padding-right: 0px;
  }
  .choose-login-subtitle-copy {
    margin-left: 0%;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    font-size: 24px;
    text-align: center;
    text-transform: none;
  }
  .text-block-4 {
    text-align: left;
  }
  .text-block-5 {
    margin-left: 2%;
    border-style: none;
  }
  .div-block-6 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    margin-bottom: 2%;
    margin-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .div-block-7 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .image-2-copy-copy {
    max-height: 100px;
  }
  .div-block-9 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .choose-login-hero-left-copy {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .image-2-copy-copy {
    max-height: 200px;
    margin-right: 5%;
  }
  .div-block-6-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    margin-bottom: 2%;
    margin-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .blue-heading-copy-copy {
    margin-top: 0px;
    margin-left: 0%;
    padding-right: 0px;
    font-size: 54px;
    text-align: left;
    text-transform: none;
  }
  .text-block-5-copy {
    width: auto;
    margin-left: 2%;
    border-style: none;
    color: #fff;
    font-size: 34px;
    text-align: left;
  }
  .image-2-copy-copy-copy {
    max-height: 100px;
  }
  .landing-page-hero-copy {
    padding-top: 0%;
    padding-bottom: 0%;
  }
  .image-2-copy-copy-copy {
    max-height: 250px;
  }
  .image-2-copy-copy {
    max-height: 50px;
    margin-right: 0%;
  }
  .blue-heading-copy-copy {
    margin-top: 0px;
    margin-left: 0%;
    padding-right: 0px;
  }
  .choose-login-hero-right-copy {
    padding: 2% 3%;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    background-color: #eee;
  }
}

@media screen and (min-width: 1920px) {
  .hp-subheading {
    max-width: 500px;
  }
  .choose-login-subtitle {
    margin-bottom: 20px;
    line-height: 150%;
  }
  .link {
    font-size: 18px;
    line-height: 145%;
  }
  .footer {
    background-color: #eee;
  }
  .text-block-2 {
    color: #444;
  }
  .field-label {
    line-height: 145%;
    font-weight: 700;
  }
  .submit-button {
    font-family: brandon-grotesque, sans-serif;
    font-size: 16px;
  }
  .paragraph-6 {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: 145%;
    font-weight: 400;
  }
  .button {
    font-family: brandon-grotesque, sans-serif;
    font-size: 16px;
  }
  .footer-nav {
    color: #444;
  }
  .help-info {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: 145%;
    font-style: italic;
    font-weight: 400;
  }
  .help-info-copy {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: 145%;
  }
  .choose-login {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    padding: 0%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    border: 1px none #000;
    background-color: #fff;
  }
  .choose-login-div {
    margin-right: 2%;
    margin-left: 2%;
    padding: 2%;
  }
  .old-login {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    background-color: #005777;
    text-align: center;
  }
  .choose-login-hero-right {
    max-width: 600px;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .choose-login-link {
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    font-size: 18px;
    line-height: 145%;
  }
  .blue-heading {
    padding-right: 0px;
  }
  .choose-login-div-blue {
    margin-right: 0%;
    margin-left: 0%;
    padding: 2%;
  }
  .blue-heading-copy {
    padding-right: 0px;
  }
  .choose-login-subtitle-copy {
    margin-bottom: 20px;
    line-height: 150%;
  }
  .blue-heading-copy-copy {
    padding-right: 0px;
  }
  .blue-heading-copy-copy {
    padding-right: 0px;
  }
  .choose-login-hero-right-copy {
    max-width: 600px;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}

@media screen and (max-width: 991px) {
  .hero {
    height: auto;
    margin-top: 0px;
    padding: 0px;
    background-color: transparent;
    background-image: none;
    background-size: auto;
  }
  .hero-content {
    max-width: none;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navigation {
    z-index: 999;
    padding-right: 10px;
    background-color: #005777;
  }
  .navigation-container {
    height: 100px;
  }
  .nav-menu {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #005777;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  }
  .nav-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 5%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .menu-button:active {
    background-color: transparent;
  }
  .menu-button.w--open {
    background-color: #D44427;
    color: #D44427;
  }
  .menu-button.w--open:focus {
    background-color: #4e4e4e;
  }
  .icon-2 {
    color: #fff;
    font-size: 32px;
  }
  .image-2 {
    height: 200px;
    max-height: none;
    max-width: none;
    margin-top: 0px;
  }
  .right-hero {
    width: 100%;
    height: auto;
    max-height: none;
    max-width: none;
    margin-left: 0px;
    padding-right: 5%;
    padding-left: 5%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .hp-heading-blue {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 48px;
  }
  .hp-heading-red {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 48px;
  }
  .byj-wrap {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
  }
  .left-hero {
    width: 100%;
    margin-bottom: 20px;
    padding-left: 5%;
  }
  .hp-subheading {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .choose-login-subtitle {
    text-align: left;
  }
  .announcement-bar {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .announcement-wrap {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .announcement-icon {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .paragraph-top {
    max-width: 550px;
    text-align: center;
  }
  .navigation-2 {
    z-index: 999;
    padding-right: 10px;
  }
  .nav-menu-2 {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #4e4e4e;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  }
  .nav-link-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .menu-button-2:active {
    background-color: transparent;
  }
  .menu-button-2.w--open {
    background-color: #D44427;
  }
  .menu-button-2.w--open:focus {
    background-color: #4e4e4e;
  }
  .hp-photo.a {
    width: 50%;
  }
  .hp-photo.b {
    width: 50%;
  }
  .hp-photo.c {
    display: none;
  }
  .hp-photo.d {
    display: none;
  }
  .footer {
    padding-right: 5%;
    padding-left: 5%;
  }
  .footer-flex-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .footer-image {
    margin-bottom: 20px;
  }
  .text-block-2 {
    text-align: center;
  }
  .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .body-2 {
    padding-top: 0%;
    padding-right: 0%;
    padding-left: 0%;
  }
  .two-column-view {
    height: auto;
    margin-top: 0px;
    padding: 0px 5%;
    background-color: transparent;
    background-image: none;
    background-size: auto;
  }
  .left-column {
    display: none;
    margin-bottom: 20px;
  }
  .right-column {
    width: 100%;
    height: auto;
    max-height: none;
    max-width: none;
    margin-left: 0px;
  }
  .submit-button {
    font-weight: 600;
  }
  .button {
    font-weight: 600;
  }
  .nav-2-columns {
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 10px;
    background-color: #005777;
  }
  .div-block-5 {
    width: auto;
  }
  .footer-nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px none #00aeef;
    text-decoration: none;
  }
  .choose-login {
    height: auto;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .choose-login-hero-right {
    width: 100%;
    height: auto;
    max-height: none;
    max-width: none;
    margin-left: 0px;
    padding: 5%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-top-right-radius: 0px;
  }
  .choose-login-hero-left {
    width: 100%;
    margin-bottom: 0px;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 5%;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .choose-login-subheading {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 21px;
  }
  .blue-heading {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 36px;
  }
  .choose-login-div-blue {
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .landing-page-hero {
    max-width: none;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .image-2-copy {
    height: 100px;
    max-height: none;
    max-width: none;
    margin-top: 0px;
  }
  .blue-heading-copy {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 36px;
    text-align: center;
  }
  .image-2-copy-copy {
    height: 100px;
    max-height: none;
    max-width: none;
    margin-top: 0px;
  }
  .choose-login-subtitle-copy {
    text-align: left;
  }
  .text-block-4 {
    text-align: center;
  }
  .image-2-copy-copy {
    height: 100px;
    max-height: none;
    max-width: none;
    margin-top: 0px;
  }
  .choose-login-hero-left-copy {
    width: 100%;
    margin-bottom: 20px;
    padding-left: 5%;
  }
  .image-2-copy-copy {
    height: 100px;
    max-height: none;
    max-width: none;
    margin-top: 0px;
  }
  .blue-heading-copy-copy {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 36px;
  }
  .image-2-copy-copy-copy {
    height: 100px;
    max-height: none;
    max-width: none;
    margin-top: 0px;
  }
  .landing-page-hero-copy {
    height: auto;
    max-width: none;
    padding-top: 20px;
    padding-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .image-2-copy-copy-copy {
    height: 100px;
    max-height: none;
    max-width: none;
    margin-top: 0px;
  }
  .image-2-copy-copy {
    height: 100px;
    max-height: 50px;
    max-width: none;
    margin-top: 0px;
  }
  .term-wrap {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  .terms {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .term-heading {
    text-align: left;
  }
  .term-paragraph {
    margin-bottom: 5%;
  }
  .term-date {
    height: auto;
    margin-bottom: 5%;
  }
  .terms-copy {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .blue-heading-copy-copy {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 36px;
    text-align: center;
  }
  .choose-login-hero-right-copy {
    width: 100%;
    height: auto;
    max-height: none;
    max-width: none;
    margin-left: 0px;
    padding: 5%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-top-right-radius: 0px;
  }
}

@media screen and (max-width: 767px) {
  .hero {
    height: auto;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .h1 {
    font-size: 48px;
    line-height: 54px;
  }
  .nav-menu {
    background-color: #005777;
  }
  .menu-button.w--open {
    background-color: #D44427;
  }
  .image-2 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .right-hero {
    margin-left: 0px;
  }
  .hp-heading-blue {
    font-size: 36px;
  }
  .hp-heading-red {
    font-size: 36px;
  }
  .hp-subheading {
    font-size: 24px;
  }
  .hero-button-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .hero-button-wrap a:first-child {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .hero-button-wrap a:not(:last-child) {
    margin-bottom: 20px;
  }
  .choose-login-subtitle {
    padding-right: 5%;
    padding-left: 5%;
  }
  .nav-menu-2 {
    background-color: #4e4e4e;
  }
  .menu-button-2.w--open {
    background-color: #4e4e4e;
  }
  .footer {
    padding: 40px 20px;
  }
  .footer-image {
    -o-object-fit: contain;
    object-fit: contain;
  }
  .body-2 {
    padding-top: 0%;
    padding-right: 0%;
    padding-left: 0%;
  }
  .two-column-view {
    height: auto;
    padding-top: 0px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .left-column {
    display: none;
  }
  .right-column {
    margin-left: 0px;
  }
  .column-heading {
    font-size: 18px;
  }
  .nav-2-columns {
    display: block;
  }
  .choose-login {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .choose-login-div {
    margin-top: 2%;
    margin-bottom: 2%;
  }
  .old-login {
    margin-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .choose-login-hero-right {
    margin-left: 0px;
  }
  .choose-login-subheading {
    font-size: 18px;
  }
  .blue-heading {
    font-size: 24px;
  }
  .choose-login-div-blue {
    margin-top: 0%;
    margin-bottom: 2%;
  }
  .image-2-copy {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .blue-heading-copy {
    font-size: 24px;
  }
  .image-2-copy-copy {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .choose-login-subtitle-copy {
    padding-right: 5%;
    padding-left: 5%;
  }
  .image-2-copy-copy {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .image-2-copy-copy {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .blue-heading-copy-copy {
    font-size: 24px;
  }
  .image-2-copy-copy-copy {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .image-2-copy-copy-copy {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .image-2-copy-copy {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .blue-heading-copy-copy {
    font-size: 24px;
  }
  .choose-login-hero-right-copy {
    margin-left: 0px;
  }
  section.campus-opt-group {
      width: 100%
  }
}

@media screen and (max-width: 479px) {
  .hero {
    width: auto;
    height: auto;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .hero-content {
    padding-right: 10px;
    padding-left: 10px;
  }
  .h1 {
    font-size: 30px;
    line-height: 42px;
    letter-spacing: 0px;
  }
  .navigation-container {
    height: 60px;
  }
  .menu-button:active {
    background-color: grey;
  }
  .image {
    width: 150px;
  }
  .image-2 {
    height: 150px;
  }
  .right-hero {
    height: auto;
    max-height: none;
  }
  .hp-heading-blue {
    padding-right: 0px;
    font-size: 36px;
    line-height: 52px;
  }
  .hp-heading-red {
    font-size: 36px;
    line-height: 52px;
  }
  .byj-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .hp-subheading {
    margin-top: 20px;
    font-size: 21px;
  }
  .choose-login-subtitle {
    text-align: center;
  }
  .announcement-bar {
    height: auto;
  }
  .announcement-wrap {
    height: auto;
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .heading {
    font-size: 21px;
    line-height: 32px;
  }
  .faqs-heading {
    padding-right: 5%;
    padding-left: 5%;
  }
  .question-title {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .faq-wrap {
    margin-right: 0px;
    margin-left: 0px;
  }
  .text-block {
    font-size: 14px;
    text-align: center;
  }
  .navigation-container-2 {
    height: 60px;
  }
  .menu-button-2:active {
    background-color: grey;
  }
  .hp-photo.a {
    width: 100%;
  }
  .hp-photo.b {
    display: none;
  }
  .footer {
    padding-right: 20px;
    padding-left: 20px;
    text-align: left;
  }
  .footer-flex-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer-logo-link {
    height: 60px;
  }
  .footer-heading {
    margin-top: 20px;
  }
  .text-block-2 {
    text-align: center;
  }
  .two-column-view {
    width: auto;
    height: auto;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .right-column {
    height: auto;
    max-height: none;
  }
  .field-label {
    font-weight: 700;
  }
  .column-heading {
    font-size: 16px;
    line-height: 32px;
  }
  .column-heading.white {
    font-size: 16px;
    line-height: 145%;
    text-align: center;
  }
  .announcement-model-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .image-5 {
    margin-right: 0px;
  }
  .choose-login {
    height: auto;
  }
  .choose-login-hero-right {
    height: auto;
    max-height: none;
  }
  .choose-login-hero-left {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .choose-login-subheading {
    margin-top: 20px;
    font-size: 21px;
  }
  .blue-heading {
    padding-right: 0px;
    font-size: 24px;
    line-height: 120%;
  }
  .choose-login-div-blue {
    margin-bottom: 0%;
  }
  .landing-page-hero {
    padding-right: 10px;
    padding-left: 10px;
  }
  .image-2-copy {
    height: 150px;
  }
  .blue-heading-copy {
    padding-right: 0px;
    font-size: 24px;
    line-height: 120%;
  }
  .image-2-copy-copy {
    height: 150px;
  }
  .choose-login-subtitle-copy {
    margin-left: 0%;
  }
  .image-2-copy-copy {
    height: 150px;
  }
  .div-block-8 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .image-2-copy-copy {
    height: 150px;
  }
  .blue-heading-copy-copy {
    padding-right: 0px;
    font-size: 24px;
    line-height: 120%;
  }
  .image-2-copy-copy-copy {
    height: 150px;
  }
  .landing-page-hero-copy {
    padding-right: 0px;
    padding-left: 0px;
  }
  .image-2-copy-copy-copy {
    height: 150px;
  }
  .image-2-copy-copy {
    height: 150px;
  }
  .div-block-8-copy {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .blue-heading-copy-copy {
    padding-right: 0px;
    font-size: 24px;
    line-height: 120%;
  }
  .choose-login-hero-right-copy {
    height: auto;
    max-height: none;
  }
}
