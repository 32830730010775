.table {
  border-radius: 5px;
  overflow: hidden;

  .radio,
  .checkbox {
    position: relative;
    height: 20px;
    display: block;
    width: 20px;
    padding: 0px 0px;
    margin: 0px 5px;
    text-align: center;

    .icons {
      left: 5px;
    }
  }

  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    padding: 12px 8px;
    vertical-align: middle;
  }

  > thead > tr {
    background-color: $uwDarkBlue;
  }

  > tbody > tr > td {
    font-size: 14px;
    //border: 2px solid $uwBlue;
  }

  > thead > tr > th {
    border-bottom-width: 1px;
    font-size: $font-size-small;
    text-transform: uppercase;
    color: white;
    font-weight: $font-weight-normal;
    padding-bottom: 5px;
  }

  .td-actions .btn {
    @include opacity(0.36);

    &.btn-xs {
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  .td-actions {
    min-width: 90px;
  }

  > tbody > tr {
    position: relative;

    &:hover {
      .td-actions .btn {
        @include opacity(1);
      }
    }
  }
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

table tr {
  background-color: white;
  //border: 1px solid red;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: center;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table caption {
    font-size: 1.3em;
  }

  .table > tbody > tr > td {
    border: none;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border: none !important;
    background-color: $uwLightGray;
    display: block;
    margin-bottom: 1em;
  }

  table td {
    display: block;
    font-size: 0.8em;
    text-align: right;
    border-radius: 0px !important;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .addColumn {
    background-color: lightgray;
  }
}
