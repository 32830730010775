.constellation-bg {
	background: linear-gradient(to bottom, #5EB5D5 3%, #005777 47%, #003447 86%);
	position: fixed;
	z-index: -900;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
}

.constellation-bg::after {
	content: '';
	background-image: url(../../img/constellation.svg);
	max-width: 1466px;
	max-height: 921px;
	background-size: 1466px 921px;
	background-repeat: no-repeat;
	position: fixed;
	z-index: -800;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
  }

  @media (min-width: 1466px) {
	.constellation-bg::after {
		margin-left: calc((100% - 1466px) / 2)
	}
  }

  input[type="button"].btn-general {
	background-color: $uwBlue
  }

  input[type="button"].primary,
  .submit-button,
  .announcement-bar {
	background-color: $uwRed
  }

  .link,
  section.find-prog a,
  section.find-prog a:hover {
	color: $uwRed;
  }

  .div-block-2,
  .sidebarHeader {
	background-color: $uwDarkBlue;
  }

  /* [data-layout='home'] ********************************************************************************
  */

  [data-layout='home'] .constellation-bg {
	background: unset;
	background-color: $uwDarkBlue
  }
  
  [data-layout='home'] .constellation-bg::after {
	background-position-y: -379px;
	background-color: $uwDarkBlue;
	opacity: 0.45;
  }

  [data-layout='home'] div.content {
	display: block;
	width: 100%;
	background-color: #fff;
	padding: 40px 0;
	margin: 0 auto;
  }

  @media (max-width: 1454px) {
	[data-layout='home'] div.content {
		border-top-left-radius: 0;
		border-top-right-radius: 0
	}
  }
  
  [data-layout='home'] div.content > div:first-child {
	float: left;
	height: 100%;
	width: 40%;
	margin-left: 3%;
	margin-right: 2%;
	text-align: center;
  }
  
  [data-layout='home'] div.content h1 {
	font-family: brandon-grotesque,sans-serif;
	color: $uwBlue;
	font-size: 54px;
	font-style: normal;
	font-weight: 500;
	line-height: 76px;
	text-transform: uppercase;
	text-align: center;
  }
  
  [data-layout='home'] div.content h1 span {
	color: $uwRed
  }
  
  [data-layout='home'] div.content h2 {
	font-family: brandon-grotesque,sans-serif;
	color: $dark-gray;
	font-size: 36px;
	line-height: 34px;
	font-weight: 500;
	margin-bottom: 50px;
	text-transform: uppercase;
	text-align: center
  }
  
  [data-layout='home'] div.content > div:last-child {
	display: inline-block;
	width: 50%;
	text-align: center;
	margin: 0 auto
  }
  
  [data-layout='home'] div.content > div:last-child div {
	width: 512px;
	max-width: calc(100% - 24px);
	margin: 24px auto 16px;
	display: flex;
	flex-direction: row;
  }
  
  [data-layout='home'] div.content input {
	height: 55px;
	width: 250px;
	max-width: calc(50% - 6px)
  }
  
  [data-layout='home'] div.content input[type="button"].btn-general + input[type="button"].btn-general {
	margin-left: 0
  }
  
  [data-layout='home'] div.content input[type="button"].btn-general + input[type="button"].btn-general:last-child {
	margin-left: 12px
  }
  
  [data-layout='home'] div.content .primary {
	max-width: 100%
  }
  
  @media (max-width: 1150px) {
	[data-layout='home'] div.content > div:last-child div {
		flex-direction: column;
	  }
  
	  [data-layout='home'] div.content input[type="button"].btn-general + input[type="button"].btn-general:last-child {
		  margin-left: 0;
		  max-width: unset
	  }
  }

  @media (max-width: 991px) {
	[data-layout='home'] div.content {
	  padding: 20px 0
	}
  
	[data-layout='home'] div.content > div:first-child {
	  float: unset;
	  width: 100%;
	  margin: 0;
	}
  
	[data-layout='home'] div.content > div:last-child {
	  width: 100%
	}
  
	[data-layout='home'] div.content > div:last-child div {
	  width: 512px;
	  margin: 20px auto 10px;
	}
  
	[data-layout='home'] div.content h1 {
	  font-size: 48px;
	  line-height: 58px
	}
  
	[data-layout='home'] div.content h2 {
	  font-size: 28px;
	  line-height: 28px;
	  margin: 0
	}
  
	[data-layout='home'] input[type="button"].btn-general {
	  margin-top: 5px;
	  margin-bottom: 5px
	}
  }
  
  @media (max-width: 536px) {
	[data-layout='home'] div.content input {
	  max-width: unset;
	  width: 100%
	}
  }

  [data-layout='home'] .navigation-container a {
	flex-direction: column;
  	justify-content: center;
  	display: flex;
	margin: 0
  }

  [data-layout='home'] .navigation-container a img {
	margin: 0;
	padding: 0
  }

  /* [data-layout='onboard'] ********************************************************************************
  */
  [data-layout='onboard'] .constellation-bg::after {
	background-position-y: 60px;
  }

  /* [data-layout='primary'] ********************************************************************************
  */
  @media (min-width: 1466px) {
	[data-layout='primary'] .constellation-bg::after {
		margin-left: calc((100% - 1466px) / 4);
		background-position-y: 36px
	}
  }

  /* [data-app="true'] **************************************************************************************
  */
  [data-app="true"] .sidebarHeader {
	background-color: $uwRed;
  }

  [data-app="true"] .table > thead > tr {
	background-color: $uwBlue;
  }